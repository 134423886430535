import { BackArrow } from "commons/svgs";
import NavigationButtonWithCheckUnSaved from "components/navigation/NavigationButtonWithCheckUnSaved";
import PayRollMonthSearch from "components/search/PayRollMonthSearch";
import ActionNextButton from "commons/ui/ActionNextButton";
import ActionDeleteButton from "commons/ui/ActionDeleteButton";
import GlobalActionButton from "commons/ui/GlobalActionButton";
import { convertDateMonthYear } from "helpers/date";
import PayRollHistory from "components/payRollHistory/PayRollHistory";
import Modal from "components/modal/Modal";
import { useHeaderMonthPayrollActions } from "hooks/useHeaderMonthPayrollActions";

import {
  BackSvg,
  DivElement,
  TopSection,
  SpanElement,
  HeaderTopSection,
  HeaderBottomSection,
  AddCrewButton,
  PayRollSearchContainer,
  NextDeleteSection,
  NextDeleteDiv,
  HeaderTopSectionInner,
  HeaderTopSectionInnerHistory,
} from "./monthPayrollStyles";

const HeaderMonthPayRoll = ({ monthPayrollID }) => {
  const {
    privileges,
    modal,
    modalDelete,
    IsEditable,
    Month,
    actions: {
      action,
      ActionCancelForDelete,
      ActionOpenModalForDelete,
      ActionCancelForSave,
      ActionContinueForSave,
      deletePayroll,
      savePayrollItems,
      submitPayroll,
      rejectPayroll,
      approvePayroll,
      processPayroll,
    },
  } = useHeaderMonthPayrollActions(monthPayrollID);

  console.log(Month);

  if (Month) {
    sessionStorage.setItem("month", JSON.stringify(Month));
  }

  return (
    <>
      <HeaderTopSection>
        <HeaderTopSectionInner>
          <TopSection>
            <NavigationButtonWithCheckUnSaved
              action={action}
              navigation={"crew-payroll"}
            >
              <BackSvg>
                <BackArrow />
              </BackSvg>
              <SpanElement className={"big-heading"}>
                Payroll for{" "}
                {Month &&
                  Month.length > 0 &&
                  convertDateMonthYear(Month[0].datedue)}
              </SpanElement>
            </NavigationButtonWithCheckUnSaved>
          </TopSection>
          <DivElement
            className={"body-secondary-b"}
            color={
              Month && Month.length > 0 && Month[0].status === "In Progress"
                ? "inprogress"
                : "submitted"
            }
          >
            {Month && Month.length > 0 ? Month[0].status : "In Progress"}
          </DivElement>
        </HeaderTopSectionInner>
        <HeaderTopSectionInnerHistory>
          <PayRollHistory monthPayrollID={monthPayrollID} />
        </HeaderTopSectionInnerHistory>
      </HeaderTopSection>
      <HeaderBottomSection>
        <PayRollSearchContainer>
          <PayRollMonthSearch />
        </PayRollSearchContainer>
        {privileges === "Vessel" && (
          <NextDeleteSection>
            {IsEditable && (
              <>
                <AddCrewButton onClick={savePayrollItems}>
                  <SpanElement>SAVE</SpanElement>
                </AddCrewButton>
                <NextDeleteDiv>
                  <ActionDeleteButton action={ActionOpenModalForDelete} />
                </NextDeleteDiv>
                <NextDeleteDiv>
                  <ActionNextButton action={submitPayroll} />
                </NextDeleteDiv>
              </>
            )}
          </NextDeleteSection>
        )}
        {privileges === "Accounting" &&
          Month &&
          Month.length > 0 &&
          resolveAccountingButtonsIsSubmited(Month[0]) && (
            <NextDeleteSection>
              <>
                <GlobalActionButton
                  action={approvePayroll}
                  label="APPROVE"
                  buttonTheme="primary"
                />
                <GlobalActionButton
                  action={rejectPayroll}
                  label="REJECT"
                  buttonTheme="secondary"
                />
                <NextDeleteDiv>
                  <ActionDeleteButton action={ActionOpenModalForDelete} />
                </NextDeleteDiv>
              </>
            </NextDeleteSection>
          )}

        {privileges === "Accounting" &&
          Month &&
          Month.length > 0 &&
          resolveAccountingButtonsApproved(Month[0]) && (
            <NextDeleteSection>
              <>
                <GlobalActionButton
                  action={processPayroll}
                  label="PROCESS"
                  buttonTheme="primary"
                />
                <GlobalActionButton
                  action={rejectPayroll}
                  label="REJECT"
                  buttonTheme="secondary"
                />
                <NextDeleteDiv>
                  <ActionDeleteButton action={ActionOpenModalForDelete} />
                </NextDeleteDiv>
              </>
            </NextDeleteSection>
          )}
      </HeaderBottomSection>
      {modalDelete && (
        <Modal
          confirm={deletePayroll}
          cancel={ActionCancelForDelete}
          text="Are you sure for this action? This Will delete the Payroll."
        />
      )}
      {modal && (
        <Modal
          confirm={ActionContinueForSave}
          cancel={ActionCancelForSave}
          navigation="crew-payroll"
          text="Changes you made may not be saved."
        />
      )}
    </>
  );
};

const resolveAccountingButtonsApproved = (Month) => {
  if (Month.status === "Approved") return true;
};

const resolveAccountingButtonsIsSubmited = (Month) => {
  if (Month.status === "Submitted") return true;
};

export default HeaderMonthPayRoll;
