import React from "react";
import {useGetCrewAndPayRoll} from "hooks/useGetCrewAndPayRoll";

import AccountingPayrollView from "./AccountingPayrollView"

const AccountingPayrollContainer = () => {

    const stateContext = useGetCrewAndPayRoll()

    return (
       <AccountingPayrollView accountingPayroll={stateContext['payRollList']}/>
    );
};

export default AccountingPayrollContainer;
