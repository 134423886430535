import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Accounting from './pages/Accounting';
import CrewPayroll from './pages/CrewPayroll';
import MonthPayRoll from './pages/MonthPayRoll';
import Login from './pages/Login';
import { useAuthDataContext } from 'context/AuthContext';
import page404 from "./pages/page404";

const PrivateRoute = ({ component,path, ...options }) => {
  const { authData: { user, privileges } } = useAuthDataContext();
  const finalComponent = user === 'authenticated' ? component : Login;

  if( user && privileges === 'Accounting' && path === '/' ){
    return <Redirect to='/accounting'/>;
  }

  if( user && privileges === 'Accounting' && path === '/crew-payroll' ){
    return <Redirect to='/accounting'/>;
  }

  if( user && privileges === 'Vessel' && path === '/' ){
    return <Redirect to='/crew-payroll'/>;
  }

  if( user && privileges === 'Vessel' && path === '/accounting' ){
    return <Redirect to='/crew-payroll'/>;
  }
 
  return (user && <Route {...options} component={finalComponent} />) || null;
};

function App() {
  
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={Login} />
        <PrivateRoute path="/accounting" exact component={Accounting} />
        <PrivateRoute path="/" exact component={CrewPayroll} />
        <PrivateRoute path="/crew-payroll" exact component={CrewPayroll} />
        <PrivateRoute path="/month-payroll" exact component={MonthPayRoll} />
        <Route path="/404" component={page404} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;
