import { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import { MaxContainer } from 'commons/ui/styles';
import styled, { ThemeProvider } from 'styled-components';
import LoginHeader from 'components/headers/LoginHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import {useAuthDataContext} from "context/AuthContext";
import GoogleLogin from 'react-google-login';

const cookies = new Cookies();

const LoginText = styled.h2`
  text-align: center;
  padding-top: 156px;
  color: ${props => props.theme.data.light.colors.NightSky};
`;

const LoginForm = styled.h2`
  width: 306px;
  display: block;
  margin: 0 auto;
  color: ${props => props.theme.data.light.colors.NightSky};
  padding-top: 22px;
`;

const Label = styled.label`
  width: 306px;
  display: block;
  margin: 0 auto;
  color: ${props => props.theme.data.light.colors.NightSky};
`;

const LoginInput = styled.input`
  width: 306px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 14px;
  margin: 5px 0 15px 0;
  color: ${props => props.theme.data.light.colors.NightSky};
`;

const SubmitInput = styled.input`
  width: 306px;
  background: ${props => props.theme.data.light.colors.NightSky} !important;
  color: ${props => props.theme.data.light.colors.White} !important;
  padding: 14px;
  border: 0;
  margin: 5px 0 15px 0;
  cursor: pointer;
`;

const GoogleButtonDiv = styled.div`
    width: 306px;
    padding: 14px;
    border: 0;
    margin: 5px 0 15px 0;
    cursor: pointer;
    margin: 0 auto;
    cursor: pointer;
    color: #fff;
    > button{
        background-color: #5385EC !important;
        color: #fff  !important;
        width: 100% !important;
        position: relative;
        height: 43px;
        > div{
         position: absolute;
         left: 0;
         top: 0px;
        }
        > span{
          text-align: center;
          width: 100%;
          padding: 9px !important;
          font-family: SF Pro Display;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
        }
    }
`;

const schema = yup.object().shape({
  email: yup.string(),
  password: yup.string(),
});

const Login = () => {
  const theme = useContext(ThemeContext);
  const {onLogin} = useAuthDataContext();

  const {
    register,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    function loginUser() {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/login`,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          username: data.username,
          password: data.password,
        },
      })
        .then(response => {
          const {name,vessel,username,user,privileges,token} = response.data;
          cookies.set('IYCtoken', token, { maxAge: 50000000, domain:  process.env.REACT_APP_COOKIE_DOMAIN});
          onLogin({name,vessel,username,user,privileges})
          if(privileges === 'Vessel'){
            return window.location.href = `/crew-payroll`;
          }
          return window.location.href = `/accounting`;
        })
        .catch(error => {
          alert('Wrong Password');
        });
    }
    loginUser();
  };

  const handleLogin = async googleData => {
    console.log(googleData)
    if(googleData.error){
      return
    }
    function loginUser() {
      const {email} = googleData.profileObj
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/v1/auth/google`,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          tokenAuth: email
        },
      }).then(response => {
        const {name, vessel, user, username, privileges, token} = response.data;
        cookies.set('IYCtoken', token, {maxAge: 50000000, domain: process.env.REACT_APP_COOKIE_DOMAIN});
        onLogin({name, vessel, user, username, privileges})
        if (privileges === 'Vessel') {
          return window.location.href = `/crew-payroll`;
        }
        return window.location.href = `/accounting`;
      })
      .catch(error => {
        alert('Wrong Password');
      });
    }
    loginUser()
  }

  return (
    <ThemeProvider theme={theme}>
      <LoginHeader />
      <MaxContainer>
        <LoginText>SIGN IN</LoginText>
        <GoogleButtonDiv>
          <GoogleLogin
              clientId={'628445088392-0iom8cdaenepqgfig9cls80t4ti1krib.apps.googleusercontent.com'}
              buttonText="Sign in with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={'single_host_origin'}
          />
        </GoogleButtonDiv>
        <Label className={'body-primary-l text-center'}>- or sign in with your userame -</Label>
        <LoginForm>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label htmlFor={'username'} className={'body-primary-l'}>
              Your Userame  
            </Label>
            <LoginInput placeholder={'email or username'} {...register('username')} />
            <Label htmlFor={'password'} className={'body-primary-l'}>
              Your Password
            </Label>
            <LoginInput type="password" placeholder={'password'} {...register('password')} />
            <SubmitInput className="body-primary-l" type="submit" value="SIGN IN"/>
          </form>
        </LoginForm>
      </MaxContainer>
    </ThemeProvider>
  );
};

export default Login;
