import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  width:100vw;
  height: 100%;
  left: 0;
  bottom: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
`;

export const ModalContainerBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: ${props => props.theme.data.light.colors.NightSky};
  opacity: 0.4;
  z-index: 1;
`;

export const ModalContainerInner = styled.div`
  background: ${props => props.theme.data.light.colors.White};
  z-index: 2;
  position: relative;
  width: 728px;
  margin: 0 auto;
  margin-top: 43px;
  padding-bottom: 134px;
`;

export const ModalTextFinalStep = styled.div`
  width: 475px;
  margin: 0 auto;
`;

export const ModalText = styled.div`
 ${props => props.theme.data.light.typography['body/paragraph-L']};
 padding: 125px 0 62px 0;
`;

export const ModalClose = styled.div`
    position: absolute;
    right: 23px;
    top: 23px;
    cursor: pointer;
`;

export const ModalButtonsArea = styled.div`
   display: block;
   text-align: center;
   > span {
     margin: 5px;
   }
`;

