import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import {ActionConfirmButton, NextButtonSubmit, SmallNextSubmitArrowSvg} from 'commons/ui/styles';
import {SubmitArrow} from 'commons/svgs';

const ActionConfirm  = ({ action }) => {
    const theme = useContext(ThemeContext);

    return (
        <ThemeProvider theme={theme}>
            <ActionConfirmButton type="button" onClick={() => action && action()} >
                <NextButtonSubmit>
                    <SmallNextSubmitArrowSvg>
                        <SubmitArrow color={'#090E36'}/>
                    </SmallNextSubmitArrowSvg>
                </NextButtonSubmit>
                Confirm
            </ActionConfirmButton>
        </ThemeProvider>
    );
};

export default ActionConfirm;
