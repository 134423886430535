import React, { useEffect, useState } from 'react';

import Progress from 'react-progressbar';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from 'services/services';
import { UploadFile } from 'commons/svgs';

import {
  DropSvg,
  DropZoneContainer,
  DropText,
  DropTextSpan,
  DropBrowse,
  DropZoneFileContainer,
  DropZoneFile,
  DropZoneProgress,
  DropZoneProgressUploading, DropZoneFileContainerInner,
} from './styles';


function DropZone(props) {
  const { action, index, showDropZone } = props;

  const [show, setShow] = useState(false);

  const [uploading, setUploading] = useState(0);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const upload = () => {

    const formData = new FormData();
    setShow(true);

    formData.append('expenses', acceptedFiles[0], acceptedFiles[0].name);
    uploadFile(formData, setUploading, setShow).then(() => console.log('upload'));
    action(index, acceptedFiles[0].name);

  };

  useEffect(() => {
    if (acceptedFiles[0]) {
      upload();
    }
  }, [acceptedFiles]);

  return (
    <>
      {showDropZone === index && (
          <DropZoneFileContainerInner>
            <DropZoneContainer>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <DropText>
                    <DropSvg>
                      <UploadFile />
                    </DropSvg>
                    <DropTextSpan className={'table-secondary-td'}>
                      Drop your attachment here, or <DropBrowse>browse</DropBrowse>
                    </DropTextSpan>
                  </DropText>
                </div>
            </DropZoneContainer>
          </DropZoneFileContainerInner>
      )}
      <DropZoneFileContainer>
          <DropZoneFile></DropZoneFile>
          {show && (
              <DropZoneProgress>
                <DropZoneProgressUploading className={'body-primary-l'}>Uploading</DropZoneProgressUploading>
                <Progress completed={uploading} />
              </DropZoneProgress>
          )}
      </DropZoneFileContainer>
    </>
  );
}

export default DropZone;
