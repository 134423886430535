import React, {useContext} from 'react';
import {useLocation} from "react-router-dom";
import { ThemeContext } from 'context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import { StateContext } from 'context/DataContext';
import {useAuthDataContext} from "context/AuthContext";

import MonthPayrollList from 'components/monthPayrollList/MonthPayrollList';
import HeaderMonthPayRoll from 'components/headers/HeaderMonthPayRoll';
import PayRollMonthTotal from "components/monthPayrollList/PayRollMonthTotal/PayRollMonthTotal";
import SubmitPayroll from "components/submitPayroll/SubmitPayroll";
import Loader from "../commons/loader/Loader";
import useCheckUnsavedChanges from 'hooks/useCheckUnsavedChanges';

import { MaxContainer } from 'commons/ui/styles';

const BgColor = styled.div`
    position: fixed;
    z-index: 0;
    background: ${props => props.theme.data.light.colors.Snow};
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
`;

const MonthPayRoll = () => {
  const theme = useContext(ThemeContext);
  const { authData: { privileges } } = useAuthDataContext();
  const {unSavedChanges} = useContext(StateContext);
  const location = useLocation();

  useCheckUnsavedChanges((e)=>{
       if(!unSavedChanges){
           return null
       }
       e.preventDefault();
       e.returnValue = '';
  })
    
  return (
    <ThemeProvider theme={theme}>
      <BgColor/>
      <MaxContainer width={privileges !== 'Vessel' ? '1658px' : '1440px'}>
        <HeaderMonthPayRoll monthPayrollID={location.search.split('=')[1]}/>
        <MonthPayrollList monthPayrollID={location.search.split('=')[1]} newPayroll={location.state}/>
        <PayRollMonthTotal/>
      </MaxContainer>
      <Loader/>
      <SubmitPayroll/>
    </ThemeProvider>
  );
};

export default MonthPayRoll;
