import styled from 'styled-components';

export const DropZoneContainer = styled.section`
  border: 1px dashed #7e8ea7;
  box-sizing: border-box;
  width: 885px;
  margin: 0 auto;
  background: ${props => props.theme.data.light.colors.White};
`;

export const DropText = styled.div`
  display: block;
  width: 300px;
  margin: 0 auto;
  padding: 25px 0;
`;

export const DropTextSpan = styled.span`
  padding-left: 22px;
`;

export const DropSvg = styled.span`
  position: relative;
  top: 2px;
`;

export const DropBrowse = styled.em`
  color: ${props => props.theme.data.light.colors.Blue};
`;

export const DropZoneFileContainer = styled.aside``;

export const DropZoneFileContainerInner = styled.div`
  background: ${props => props.theme.data.light.colors.Snow};
    padding: 20px 0;
    margin-bottom: 15px;
`;

export const DropZoneFile = styled.div`
  padding: 0;
`;

export const DropZoneProgress = styled.div`
  padding: 20px 0;
  width: 384px;
  padding-left: 47px;
`;

export const DropZoneProgressUploading = styled.div`
  color: ${props => props.theme.data.light.colors.Blue};
  padding-bottom: 15px;
`;
