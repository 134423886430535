import Header from 'components/headers/Header';
import CrewList from 'components/crewList';
import PayRollList from 'components/payRollList';
import React, {useContext} from 'react';
import { ThemeContext } from 'context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import { MaxContainer } from 'commons/ui/styles';
import CreateNewPayRoll from 'components/createNewPayRoll/CreateNewPayRoll'
import Loader from "commons/loader/Loader";

const CrewPayrollContainer = styled.div`
  padding-top: 60px;
`;

const BgColor = styled.div`
    position: absolute;
    z-index: 0;
    background: ${props => props.theme.data.light.colors.Snow};
    left: 0;
    top: 0;
    height: 100%;
    width: calc(50vw - 224px);
    min-width: 503px;
`;

const CrewPayroll = () => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <BgColor/>
      <MaxContainer>
        <CrewPayrollContainer>
          <CreateNewPayRoll/>
          <CrewList/>
          <PayRollList/>
        </CrewPayrollContainer>
        <Loader/>
      </MaxContainer>
    </ThemeProvider>
  );
};

export default CrewPayroll;
