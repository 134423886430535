import React, { useContext } from 'react';
import { DispatchContext, StateContext } from 'context/DataContext';
import { ThemeContext } from 'context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import { Search } from 'commons/svgs';

const InputSearch = styled.input`
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  width: 265px;
  padding: 11px 11px 11px 43px;
`;

const SearchArea = styled.div`
  position: relative;
  display: inline-block;
  float: right;
`;

const SearchSvg = styled.span`
  position: absolute;
  left: 15px;
  top: 4px;
  z-index: 1
`;

const YachtSearch = () => {
    const dispatchDataContext = useContext(DispatchContext);
    const stateContext = useContext(StateContext);
    const theme = useContext(ThemeContext);

    const setKeyword = async input => {
        const filtered = stateContext['payRollList'] && stateContext['payRollList'].map(payroll => {
            payroll.hide = !payroll.yachtname.toLowerCase().includes(input.toLowerCase());
            return payroll;
        });
        dispatchDataContext({ type: 'payRollList', payload: filtered });
    };

    return (
        <ThemeProvider theme={theme}>
            <SearchArea>
                <SearchSvg>
                    <Search />
                </SearchSvg>
                <InputSearch key="searchKey" placeholder='Search Yatch' onChange={e => setKeyword(e.target.value)} />
            </SearchArea>
        </ThemeProvider>
    );
};

export default YachtSearch;
