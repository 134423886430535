import styled, { css } from 'styled-components'

export const Button = styled.button`
  ${props => props.buttonTheme === 'primary' && props.theme.data.light.typography['button-primary']}
  ${props => props.buttonTheme === 'secondary' && props.theme.data.light.typography['button-primary']}
  ${props =>
    props.buttonTheme === 'primary' &&
    css`
       background: ${props => props.theme.data.light.colors.White};
       color:  ${props => props.theme.data.light.colors.NightSky};
       border: 1px solid ${props => props.theme.data.light.colors.NightSky};
       padding: 9px 30px 8px 30px;
       cursor: pointer;
       &:hover{
             opacity: 0.6;
       }
    `};
    
  ${props =>
    props.buttonTheme === 'secondary' &&
    css`
       background: ${props => props.theme.data.light.colors.DirtySnow};
       color:  ${props => props.theme.data.light.colors.NightSky};
       border: 0;
       padding: 9px 30px 8px 30px;
       cursor: pointer;
       &:hover{
             background: #c8d0e4;
       }
    `};
    
    transition: all 0.35s;
`
