import React,{useState} from 'react';
import SinglePayRollRow from './SinglePayRollRow';

const PayRollListContainer = ({ data, currency,isEditable }) => {

  const [openNotes, setOpenNotes] = useState(null);
  
  const actionSetNotes = (index) =>{
      if(index === openNotes){
          setOpenNotes(null)
          return
      }
      setOpenNotes(index)
  }
  
  return data.length > 0 && data.map((payroll, i) => {
    return (
      <React.Fragment key={payroll.id + '_SinglePayRow'}>
        <SinglePayRollRow
          payroll={payroll}
          index={i}
          data={data}
          classAtr={payroll.hide ? 'hide' : ''}
          currency={currency}
          isEditable={isEditable}
          openNotes={openNotes}
          actionSetNotes={actionSetNotes}
        />
      </React.Fragment>
    );
  })
};


export default PayRollListContainer;
