import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import { ActionAddLineButtonCss } from 'commons/ui/styles';

const ActionAddLineButton = ({ children, action, label }) => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <ActionAddLineButtonCss type="button" onClick={() => action()}>
        {children || label}
      </ActionAddLineButtonCss>
    </ThemeProvider>
  );
};

export default ActionAddLineButton;
