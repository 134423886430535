import { useContext, useState, useEffect } from 'react';

import {useAuthDataContext} from "context/AuthContext";
import {DispatchContext,StateContext} from "context/DataContext";
import {deleteExpensesFile, getSingleExpenses, saveExpenses, savePayroll} from "services/services";

export const useExpensesActions = (payroll,payrolls,inputExpensesFileName,setOpenExpenses,setShowDropZone,fieldArrayMethods,openExpenses) => {
    
    const dispatchDataContext = useContext(DispatchContext);
    const stateContext = useContext(StateContext);
    const {authData:{username}} = useAuthDataContext()
    const { unSavedChangesExpenses} = useContext(StateContext);
    
    const { fields, append, remove } = fieldArrayMethods;

    const defaultExpenses = {
        amount: 0,
        amountincurrency: null,
        comment: null,
        currency: "",
        date: "",
        exchangerate: null,
        file: "",
        idfcrew: payroll.idfcrew,
        idfpayroll: payroll.idfpayroll,
        idfpayrollitem: payroll.id,
        referencecurrency: payroll.currency
    }
    
    const [expensesRow,setExpensesRow] = useState(0)
    
    const [deleteModal,setDeleteModal] = useState(false)

    const [cancelModal,setCancelModal] = useState(false)

    const [deleteFileModal,setDeleteFileModal] = useState(false)

    const [unSavedModal,setUnSavedModal] = useState(false)

    const actionUnsaved = (value) => {
        if(unSavedChangesExpenses){
            return setUnSavedModal(value)
        }
        setOpenExpenses(false)
        setTimeout(()=>dispatchDataContext({ type: 'total', payload: true }),150)
        document.documentElement.style.overflow = "auto";
    }

    const actionSaveExpensesAndBack = (expenses) => {
        actionSaveExpenses(expenses)
        setUnSavedModal(false)
        setOpenExpenses(false)
        setTimeout(()=>dispatchDataContext({ type: 'total', payload: true }),150)
        document.documentElement.style.overflow = "auto";
    }

    const actionSaveExpenses = (expenses) => {
        dispatchDataContext({ type: 'loader', payload: true })
        saveExpenses(expenses).then(data=> {
            let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
            savePayroll(final).then((data) => {
                if (data === '!Saved') {
                    alert('Something Went Wrong')
                }
                dispatchDataContext({type: 'submit', payload: false})
                dispatchDataContext({type: 'unSavedChangesExpenses', payload: false});
                getSingleExpenses(payroll.id).then((data) => {
                    if (data) {
                        remove()
                        const newDataWithExpenses = updateKeyOfObject(payrolls, payroll.id, data.payrollexpense)
                        dispatchDataContext({type: 'singleMonthPayRoll', payload: newDataWithExpenses});
                        data.payrollexpense.length > 0 && data.payrollexpense.map((item) => append(item))
                        return dispatchDataContext({type: 'loader', payload: false})
                    }
                })
            })
        })
    }

    const actionDeleteModal = (value,index) => {
        setDeleteModal(value)
        setExpensesRow(index)
    }

    const actionDeleteFileModal = (value,index) => {
        setDeleteFileModal(value)
        setExpensesRow(index)
    }

    const actionCancelModal = (value) => {
        setCancelModal(value)
    }
    
    const deleteExpensesRowAndSaveExpenses = (id) => {
        DeleteExpenses(expensesRow,id)
        dispatchDataContext({ type: 'loader', payload: true })
        const final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        savePayroll(final).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'submit', payload: false })
            setDeleteModal(false)
        })
    }

    const AddExpenses = () => {
        dispatchDataContext({ type: 'total', payload: false })
        setOpenExpenses(true);
    };

    const AddLineExpensesAction = () => {
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
        AddLineExpenses()
    }
    const deleteExpensesFileAndSavePayroll = (id) => {
        DeleteFileExpenses(expensesRow,id)
        setDeleteFileModal(false)
    }

    const actionUndo = (goBack) => {
        let payrollitemId = payroll.id
        const newDataWithExpenses = updateKeyOfObject(payrolls,payroll.id,[])
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: newDataWithExpenses });
        remove()
        dispatchDataContext({ type: 'loader', payload: true })
        getSingleExpenses(payroll.id).then((data)=>{
            if(data){
                const newDataWithExpenses = updateKeyOfObject(payrolls,payroll.id,data.payrollexpense)
                dispatchDataContext({ type: 'singleMonthPayRoll', payload: newDataWithExpenses });
                data.payrollexpense.length > 0  && data.payrollexpense.map((item)=>append(item))
                let newPayRoll = stateContext.singleMonthPayRoll.map(payroll => {
                    if (payroll.id === payrollitemId) {
                        payroll['totalexpenses'] = parseFloat(updateTotalExpenses(payroll));
                        payroll['total'] = calculateTotal(payroll);
                    }
                    return payroll;
                });
                dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
                return  dispatchDataContext({ type: 'loader', payload: false })
            }
            const newDataWithExpenses = updateKeyOfObject(payrolls,payroll.id,[defaultExpenses])
            dispatchDataContext({ type: 'singleMonthPayRoll', payload: newDataWithExpenses });
            [defaultExpenses].map((item)=>append(item))
            let newPayRoll = stateContext.singleMonthPayRoll.map(payroll => {
                if (payroll.id === payrollitemId) {
                    payroll['totalexpenses'] = parseFloat(updateTotalExpenses(payroll));
                    payroll['total'] = calculateTotal(payroll);
                }
                return payroll;
            });
            dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });

            return dispatchDataContext({ type: 'loader', payload: false })

        })
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: false });
        setCancelModal(false)

        if(goBack){
            setUnSavedModal(false)
            setOpenExpenses(false)
            dispatchDataContext({ type: 'total', payload: true })
            document.documentElement.style.overflow = "auto";
        }

    }

    const AddLineExpenses = () => {
        console.log(defaultExpenses)
        append(defaultExpenses);
        let AddExpenses = payrolls.map(Payroll => {
            if (Payroll.id === payroll.id) {
                Payroll.expenses.push(defaultExpenses);
            }
            return Payroll;
        });
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: AddExpenses });
    };

    const DeleteExpenses = (i,payRollId) => {
        
        let RemoveExpenses = payrolls.map(payroll => {
            if (payroll.id === payRollId) {
                if(payroll.expenses[i].file){
                    deleteExpensesFile(payroll.expenses[i].file,username).then(()=>console.log('deleted'))
                }

                if(payroll.expenses)
                    payroll.totalexpenses = payroll.totalexpenses - payroll.expenses[i].amount
                payroll.total = calculateTotal(payroll)
                payroll.expenses.splice(i, 1);

                if(payroll.expenses.length > 0) {
                    dispatchDataContext({ type: 'loader', payload: true })
                    saveExpenses(payroll.expenses,username).then(data=>dispatchDataContext({ type: 'loader', payload: false }))
                }

                remove(i);
            }
            return payroll;
        });

        setShowDropZone(-1);
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: RemoveExpenses });

        setTimeout(()=> {
            if(payroll.expenses.length === 0){
                payroll.expenses.push(defaultExpenses);
                append(defaultExpenses);
                let addPayroll = payrolls.map(payroll => {
                    if (payroll.id === payRollId) {
                        payroll.expenses = []
                        payroll.expenses.push(defaultExpenses);
                        payroll.totalexpenses = 0
                        dispatchDataContext({ type: 'loader', payload: true })
                        saveExpenses(payroll.expenses,username).then(data=>dispatchDataContext({ type: 'loader', payload: false }))
                    }
                    return payroll;
                });
                dispatchDataContext({ type: 'singleMonthPayRoll', payload: addPayroll });
            }
        },300)
    };

    const AddFileExpenses = (i, fileName) => {
        fields[i].file = fileName;
        let Values = {
            file: fileName
        };
        inputExpensesFileName({ target: Values }, `expenses_file_${i}`);
    };

    const DeleteFileExpenses = (i,payRollId) => {

        if(fields[i].file){
            console.log('DELETE FILE TEST')
            console.log(fields[i].file)
            deleteExpensesFile(fields[i].file,username).then(()=>console.log('deleted'))
        }

        fields[i].file = '';
        let Values = {
            file: ''
        };
        inputExpensesFileName({ target: Values }, `expenses_file_${i}`);
        setShowDropZone(-1);

        payrolls.map(payroll => {
            if (payroll.id === payRollId) {
                payroll.expenses[i].file = ''
                dispatchDataContext({ type: 'loader', payload: true })
                saveExpenses(payroll.expenses,username).then(data=>dispatchDataContext({ type: 'loader', payload: false }))
            }
            return payroll;
        });

    };

    useEffect(()=>{
        if(openExpenses){
            remove()
            dispatchDataContext({ type: 'loader', payload: true })
            getSingleExpenses(payroll.id).then((data)=>{
                if(data){
                    const newDataWithExpenses = updateKeyOfObject(payrolls,payroll.id,data.payrollexpense)
                    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newDataWithExpenses });
                    data.payrollexpense.length > 0 && data.payrollexpense.map((item)=>append(item,{shouldFocus:true,focusIndex:0}))
                    return dispatchDataContext({ type: 'loader', payload: false })
                }
                const newDataWithExpenses = updateKeyOfObject(payrolls,payroll.id,[defaultExpenses])
                dispatchDataContext({ type: 'singleMonthPayRoll', payload: newDataWithExpenses });
                [defaultExpenses].map((item)=>append(item,{shouldFocus:true,focusIndex:0}))
                return dispatchDataContext({ type: 'loader', payload: false })
            })
        }
    },[openExpenses])

    return {
        actionUnsaved: actionUnsaved,
        actionDeleteModal: actionDeleteModal,
        actionDeleteFileModal: actionDeleteFileModal,
        actionCancelModal: actionCancelModal,
        actionSaveExpensesAndBack: actionSaveExpensesAndBack,
        actionSaveExpenses:actionSaveExpenses,
        actionUndo: actionUndo,
        deleteExpensesRowAndSaveExpenses: deleteExpensesRowAndSaveExpenses,
        deleteExpensesFileAndSavePayroll: deleteExpensesFileAndSavePayroll,
        AddLineExpensesAction: AddLineExpensesAction,
        AddExpenses: AddExpenses,
        AddLineExpenses: AddLineExpenses,
        DeleteExpenses: DeleteExpenses,
        AddFileExpenses: AddFileExpenses,
        DeleteFileExpenses: DeleteFileExpenses,
        deleteModal: deleteModal,
        deleteFileModal: deleteFileModal,
        cancelModal: cancelModal,
        unSavedModal: unSavedModal,

    };
};

const calculateTotal = (payroll) => {
    return payroll.bonus + payroll.gratuities + payroll.totalexpenses + payroll.grosssalary + payroll.flights + payroll.unpaidleave + -Math.abs(parseFloat(payroll.deductions))
};

const createFinalPayrollForSave = (data) => {
    return data.map(function(item){
        return (({ bonus, comments,currency,dailyrate,daysoff,daysworked,deductions,finaltotal,flights,fullmonth,gratuities,grosssalary,id,idfcrew,idfpayroll,isprocessed,lastdatecrew,leavedailyaccrualrate,leavedaysaccrued,leavedaystaken,leavemonthendbalance,leavemonthstartbalance,month,monthlysalary,total,totalexpenses,type,unpaidleave,yacht,year }) =>
            ({ bonus, comments,currency,dailyrate,daysoff,daysworked,deductions,finaltotal,flights,fullmonth,gratuities,grosssalary,id,idfcrew,idfpayroll,isprocessed,lastdatecrew,leavedailyaccrualrate,leavedaysaccrued,leavedaystaken,leavemonthendbalance,leavemonthstartbalance,month,monthlysalary,total,totalexpenses,type,unpaidleave,yacht,year }))(item)
    })
}

const updateKeyOfObject = (array,payrollId,expenses) => {
    let objIndex = array.findIndex((obj => obj.id === payrollId));
    array[objIndex].expenses = expenses
    return array
}

const updateTotalExpenses = (payroll) =>{
    return payroll.expenses.reduce((amm,item)=> amm += item.amount,0)
}