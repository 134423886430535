import { createGlobalStyle } from 'styled-components'

export const GlobalFonts = createGlobalStyle`

@font-face {
    font-family: 'Graphik LC Web';
    src: url('${process.env.REACT_APP_API}/fonts/Graphik-Medium-Cy-Web.woff2') format('woff2'),
        url('Graphik-Medium-Cy-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}

.Graphik-Medium-Cy-Web {
    font-family: 'Graphik LC Web';
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}


@font-face {
    font-family: 'Graphik LC Web';
    src: url('${process.env.REACT_APP_API}/fonts/Graphik-RegularItalic-Cy-Web.woff2') format('woff2'),
        url('${process.env.REACT_APP_API}/fonts/Graphik-RegularItalic-Cy-Web.woff') format('woff');
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
}

.Graphik-RegularItalic-Cy-Web {
    font-family: 'Graphik LC Web';
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
}


@font-face {
    font-family: 'Graphik LC Web';
    src: url('${process.env.REACT_APP_API}/fonts/Graphik-Regular-Cy-Web.woff2') format('woff2'),
        url('${process.env.REACT_APP_API}/fonts/Graphik-Regular-Cy-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}

.Graphik-Regular-Cy-Web {
    font-family: 'Graphik LC Web';
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}


@font-face {
    font-family: 'Graphik LC Web';
    src: url('${process.env.REACT_APP_API}/fonts/Graphik-Light-Cy-Web.woff2') format('woff2'),
        url('${process.env.REACT_APP_API}/fonts/Graphik-Light-Cy-Web.woff') format('woff');
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
}

.Graphik-Light-Cy-Web {
    font-family: 'Graphik LC Web';
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
}


*{box-sizing:border-box;font-family:Graphik LC Web !important;}

`

