import styled from 'styled-components';

export const SubmitPayRollContainer = styled.div`
  position: fixed;
  width:100vw;
  height: 100%;
  left: 0;
  bottom: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
`;

export const SubmitPayRollContainerBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: ${props => props.theme.data.light.colors.NightSky};
  opacity: 0.4;
  z-index: 1;
`;

export const SubmitPayRollContainerInner = styled.div`
  background: ${props => props.theme.data.light.colors.White};
  z-index: 2;
  position: relative;
  width: 728px;
  margin: 0 auto;
  margin-top: 43px;
  padding-bottom: 134px;
`;

export const SubmitPayRollTextFinalStep = styled.div`
  width: 475px;
  margin: 0 auto;
`;

export const SubmitPayRollText = styled.div`
 ${props => props.theme.data.light.typography['body/paragraph-L']};
 padding: 125px 0 62px 0;
`;

export const SubmitButton = styled.div`
   display: block;
   width: 100%;
   float: left;
   > button {
     float: right;
   }
   
`;



export const SubmitForm = styled.form`
  width: 460px;
  margin: 0 auto;
`;

export const SubmitTextArea = styled.textarea`
  width: 100%;
  margin: 0 auto;
  padding: 12px;
  ${props => props.theme.data.light.typography['body-primary-r']};
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  margin-bottom: 26px;
  height: 102px;
  resize: none;
`;

export const SubmitClose = styled.div`
    position: absolute;
    right: 23px;
    top: 23px;
    cursor: pointer;
`;

export const SubmitPayRollLabel = styled.div`
  ${props => props.theme.data.light.typography['body-primary-r']};
  color: ${props => props.theme.data.light.colors.NightSky};
  padding-bottom: 5px;
`;

export const SubmitPayRollHeader = styled.h1`
   ${props => props.theme.data.light.typography['header-sm']};
   text-align: center;
   padding: 51px 0 31px 0;
`;

export const ConfirmButtonsArea = styled.div`
   display: block;
   text-align: center;
   > span {
     margin: 5px;
   }
`;

