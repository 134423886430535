import React, { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';

import Header from 'components/headers/Header';
import AccountingPayroll from "components/accountingPayroll";
import Loader from "commons/loader/Loader";

import styled, { ThemeProvider } from 'styled-components';
import { MaxContainer } from 'commons/ui/styles';

const CrewPayrollContainer = styled.div`
  padding-top: 60px;
`;

const Accounting = () => {
    const theme = useContext(ThemeContext);

    return (
        <ThemeProvider theme={theme}>
            <Header/>
            <MaxContainer width='876px'>
                <CrewPayrollContainer>
                    <AccountingPayroll/>
                </CrewPayrollContainer>
            </MaxContainer>
            <Loader/>
        </ThemeProvider>
    );
};

export default Accounting;
