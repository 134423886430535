import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 27px;
`;

export const LogoSection = styled.div`
  align-self: center;
  height: 30px;
`;

export const SearchSection = styled.div`
  margin-left: auto;
  align-self: center;
  padding-right: 28px;
`;

export const AvatarSection = styled.div`
  align-self: center;
  position: relative;
`;

export const AvatarName = styled.div`
  display: inline-block;
  float: left;
  color: ${props => props.theme.data.light.colors.Carbon};
  ${props => props.cssClass}
  padding: 0 13px 0 10px;
  ${props => props.theme.data.light.typography['body-primary-l']}
`;

export const Border = styled.div`
  display: inline-block;
  float: left;
  height: 20px;
  width: 1px;
  background: ${props => props.theme.data.light.colors.Carbon};
  margin-top: 2px;
  opacity: 0.4;
`;

export const MaxContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`;

export const HeaderFull = styled.div`
  background: ${props => props.theme.data.light.colors.NightSky};
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const LogOutDiv = styled.div`
  background: ${props => props.theme.data.light.colors.NightSky};
  position: absolute;
  width: 100%;
  z-index: 2;
  color: ${props => props.theme.data.light.colors.White};
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
`;

