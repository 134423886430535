import styled, { createGlobalStyle,css } from 'styled-components';

export const MonthPayRollContainer = styled.div`
  width: 100%;
  float: left;
  padding: 14px 0 118px 0;
`;

export const ListTd = styled.div`
  padding: 5.5px 0 5.5px 0;
  position: relative;
`;

export const Input = styled.input`
  width: 88px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  max-height: 39px;
`;

export const InputSmall = styled.input`
  width: 43px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  padding-left: 10px;
  text-align: center;
  max-height: 39px;
`;

export const InputDatePicker = styled.input`
  width: 187px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 7px;
  padding-right: 0;
  padding-left: 10px;
  text-align: center;
  text-transform: uppercase;
`;

export const NoteInputSmall = styled.input`
  width: 88px;
  height: 38.8px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  max-heigth: 39px;
`;

export const NoteInputLike = styled.div`
  width: 88px;
  height: 38.8px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 10px 9px 9px 9px;
  background: ${props => props.theme.data.light.colors.Snow};
  cursor: not-allowed;
`;

export const DivRelative = styled.div`
   position: relative;
`;

export const NoteCalendar = styled.span`
  position: absolute;
  right: 3px;
  background: ${props => props.disabled ? props.theme.data.light.colors.Snow : props.theme.data.light.colors.White};
  top: 10px;
  width: 30px;
  pointer-events: none;
  z-index: 1;
`;


export const TextArea = styled.textarea`
  width: 487px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  padding-right: 0;
  padding-left: 10px;
  resize: none;
`;

export const OpenNotes = styled.span`
  padding: 0 18px 0 22px;
  cursor: pointer;
  
  &.is-open-notes{
    > svg{
      transform: rotate(180deg);
    }
  }
  
`;

export const ExpensesSectionInner = styled.div`
    position: relative;
    height: calc(100% - 166px);
    overflow: auto;
    max-width: 1455px;
    margin: 0 auto;
    padding-left: 322px;
    margin-top: 166px;
    
    &:after{
        content: "";
        position: fixed;
        z-index: -1;
        background: #F9FAFC;
        width: 100vw;
        height: 100vh;
        /* right: 0; */
        top: 0;
        cursor: not-allowed;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 319px;
    }
    
    
    @media only screen and (max-width: 1450px) {
        max-width: 1138px;
        padding-left: 0;
    }
`;

export const ExpensesSectionInnerInline = styled.div`
  background: ${props => props.theme.data.light.colors.White};
  position: relative;
  border-left: 1px solid #E5E9F3;
  border-top: 1px solid #E5E9F3;
  float: left;
  min-height: 100%;
  width: 100%;
  min-width: 1118px;
  .pos-rel:nth-child(2) .add-file{
      margin-top: 23px;
      padding-right: 0;
  }
`;

export const ExpensesSection = styled.div`
  position: fixed;
  width: 100vw !important;
  height: 100vh;
  z-index: 10;
  overflow: auto;
  left: 0;
  top: 0;
`;

export const NoteSvg = styled.span`
  position: relative;
  top: 2px;
  left: 30px;
`;

export const PlusSvg = styled.span`
  position: relative;
  top: 2px;
  cursor: pointer;
`;

export const Price = styled.span`
  padding: 0 2px;
`;

export const NotesInputDates = styled.div`
   width: 187px;
   display: inline-block;
   float: left;
   margin-right: 13px;
`;

export const NotesInputDiv = styled.div`
  width: 100px;
  display: inline-block;
  float: left;
  margin-right: 13px;
`;

export const NotesTextAreaDiv = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  height: 87px;
  margin-top: 22px;
`;

export const NotesLabel = styled.label`
  display: block;
  padding-bottom: 10px;
`;

export const ExpensesDiv = styled.div`
  display: inlin-block;
  float: left;
  padding-bottom: 10px;
  padding-right: 10px;
  position: relative;
  ${props =>
    props.isEditable === true   &&
    css`
        pointer-events: none;
    `};
`;

export const ExpensesSelect = styled.select`
  padding: 9px 5px 10px 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 0;
  background:  ${props => props.theme.data.light.colors.White};
  overflow: hidden;
  width: 0;
  cursor: pointer;
  height: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
`;

export const ExpensesSelectArrow = styled.span`
    position: absolute;
    right: 14px;
    bottom: 20px;
    transform: rotate(180deg);
    background: #fff;
    width: 15px;
    z-index: 1;
`;


export const ExpensesLabel = styled.label`
  ${props => props.theme.data.light.typography['table-secondary-sm']};
  display: block;
  padding-bottom: 10px;
`;

export const ExpensesTextArea = styled.textarea`
  width: 325px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 10px;
  padding-right: 0;
  padding-left: 10px;
  resize: none;
  height: 39px;
`;

export const ExpensesHeading = styled.h1`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 28px;
  left: 140px;
  color: #090e36;
  width: calc(100% - 280px);
  color: ${props => props.theme.data.light.colors.NightSky};
`;


export const ExpensesButtonCancel = styled.div`
    background: ${props => props.theme.data.light.colors.DirtySnow};
    color: ${props => props.theme.data.light.colors.NightSky};
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 11px 29px 10px 29px;
    cursor: pointer;
    ${props => props.theme.data.light.typography['table-sd']};
`

export const ExpensesActionsButton = styled.div`
    // position: absolute;
    // text-align: center;
    // top: 20px;
    // left: initial;
    // color: #090e36;
    // right: 0;
    // width: 200px;
    display: inline-block;
    float: left;
`;


export const ExpensesHeadingContainer = styled.div`
  width: 100%;
  display: block;
  float: left;
  padding-bottom: 115px;
`;

export const ExpensesInput = styled.input`
  width: 90px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 10px 9px 10px 9px;
  max-height: 39px;
`;

export const ExpensesInputDatePicker = styled.input`
  width: 122px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  text-transform: uppercase;
  background: transparent;
`;

export const CloseExpensesDiv = styled.div`
  cursor: pointer;
  color: ${props => props.theme.data.light.colors.Blue};
  position: absolute;
  top: 32px;
  left: 30px;
`;

export const PlusSmallSvg = styled.span`
  position: absolute;
  top: 15px;
  padding-left: 2px;
`;

export const ExprensesAmmountDiv = styled.div`
  color: ${props => props.theme.data.light.colors.FireRose};
  cursor: pointer;
`;

export const CloseExpensesSVG = styled.span`
  position: relative;
  top: 2px;
  padding-right: 20px;
  padding-left: 2px;
`;

export const ExpensesAddFile = styled.div`
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 11px;
  width: 92px;
  text-align: right;
  cursor: pointer;
  display: inline-block;
  float: left;
  margin-right: 12px;
  height: 40px;
`;

export const ExpensesDownloadFile = styled.a`
  border: 1px solid ${props => props.theme.data.light.colors.Spurle};
  padding: 12px;
  width: 92px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  float: left;
  margin-right: 12px;
  display: block;
  color: ${props => props.theme.data.light.colors.Spurle};
  background: rgba(13, 186, 138, 0.1);
  pointer-events: initial;
`;

export const ExpensesDeleteFile = styled.div`
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  display: inline-block;
  float: left;
  cursor: pointer;
  display: inline-block;
`;

export const ExpensesDeleteFileSvg = styled.span`
  position: relative;
  top: 2px;
  padding: 11px;
  display: block;
  height: 38px;
`;

export const ExpensesDeleteFileNameSvg = styled.span`
  position: relative;
  top: 0;
  padding: 0;
  padding-top: 1px;
  float: left;
  cursor: pointer;
`;

export const ExpensesAddFileSvg = styled.span`
  position: relative;
  top: 2px;
  right: 8px;
`;

export const ExpensesDownloadFileSvg = styled.span`
  position: relative;
  top: 2px;
  right: 8px;
`;

export const ExpensesContainer = styled.div`
  position: relative;
  margin: 0 0 15px 30px;
  border-bottom: 1px solid ${props => props.theme.data.light.colors.DirtySnow};
  float: left;
  display: block;
  background: ${props => props.theme.data.light.colors.White};
`;

export const ExpensesDropZone = styled.div`
  width: calc(100% - 20px);
  display: block;
  float: left;
`;

export const ExpensesActionsSaveCancel = styled.div`
  width: 100%;
  margin-top: 66px;
  display: block;
  float: left;
`;

export const ExpensesDropZoneInner = styled.div`
  width: 100%;
  display: block;
  float: left;
  padding: 20px;
  background:  ${props => props.theme.data.light.colors.Snow};
  margin-bottom: 15px;
`;

export const ExpensesFieldNameFile = styled.span`
  float: left;
  padding: 0 30px 0 25px;
  color: ${props => props.theme.data.light.colors.Blue};
`;

export const NoteInputDatePicker = styled.input`
  width: 187px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.data.light.colors.NightSky};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  padding: 9px;
  padding-right: 0;
  padding-left: 15px;
  text-transform: uppercase;
  background: transparent;
`;

export const MonthPayRollTableCss = createGlobalStyle`

    .progressbar-container{
       background: #C0CCDB;
     
    }
    
    .expenses-sections > .pos-rel:nth-child(2) .add-file{
      top: 23px;
    }
    
    .add-file{
        position: relative;
        top: 0;
        padding-right: 0;
    }
    .progressbar-progress{
       background-color: #1458EC !Important;
       height: 2px !Important;
    }
    
 
    .unpaid-leave {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0.01em;
        position: relative;
        text-transform: uppercase;
    }
    
    .payroll-notes td > div{
        padding: 18px 0 18px 52px;
        display: block !important;
        float: left;
        width: 100% !important;
        background:  #F9FAFC;
    }
    
    .payroll-notes > td {
        width: 1440px !important;
        display: block;
    }

    .table.month-payroll-list.table-heading{
      border-bottom: 0;
    }
    thead.table.month-payroll-list.table-heading > tr{
      border-bottom: 0;
    }
    .table.month-payroll-list tr > td{
        border-right: 1px solid #E5E9F3;
    }
    
table {
  overflow: hidden;
}

td, th {
  position: relative;
}

 tbody tr:hover {
  background-color: ${props => props.theme.data.light.colors.HoverTable};
}




.table.month-payroll-list  tbody tr.payroll-notes:hover {
  background-color: transparent;
}
 
.table.month-payroll-list .payroll-notes td::after{
  display: none !important;
}

.table.month-payroll-list .payroll-notes th::after{
  display: none !important;
}

.table.month-payroll-list .no-border td::after{
  display: none !important;
}

.table.month-payroll-list .no-border th::after{
  display: none !important;
}

.table.month-payroll-list .payroll-notes {
  background-color: ${props => props.theme.data.light.colors.Snow};
  position: relative;
}

.table.month-payroll-list .payroll-notes::after{
    content: "";
    background-color:${props => props.theme.data.light.colors.Snow};
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: -1px;
}

.table.month-payroll-list td:hover::after,
.table.month-payroll-list th:hover::after {
  background-color: ${props => props.theme.data.light.colors.HoverTable};
}

.table.month-payroll-list td:focus::after,
.table.month-payroll-list th:focus::after {
  background-color: ${props => props.theme.data.light.colors.HoverTable};
}

.table.month-payroll-list td.no-input-field:hover::after,
.table.month-payroll-list th.no-input-field:hover::after {
  display: none !important;
}

.table.month-payroll-list td.no-input-field:focus::after,
.table.month-payroll-list th.no-input-field:focus::after {
  display: none !important;
}

td:hover::after,
thead th:not(:empty):hover::after,
td:focus::after,
thead th:not(:empty):focus::after { 
  content: '';  
  height: 10000px;
  left: 0;
  position: absolute;  
  top: -5000px;
  width: 100%;
  z-index: -1;
}

td:hover::after,
th:hover::after {
  background-color: ${props => props.theme.data.light.colors.HoverTable};
}

td:focus::after,
th:focus::after {
  background-color: ${props => props.theme.data.light.colors.HoverTable};
}
 

    
    
    
    
       
    .table.month-payroll-list tr > th:nth-child(1){
        width: 258px;
        padding-left: 52px;
    }
    
    .table.month-payroll-list tr > td:nth-child(1){
        width: 258px;
        border-right: 0;
    }
    
    .table.month-payroll-list tr > td:nth-child(1) > div{
       display: inline-block;
        width: 180px;
    }
    
    .table.month-payroll-list tr > th:nth-child(1) > div{
       padding: 0 0 0 51px;
    }
      
    .table.month-payroll-list tr > th:nth-child(2){
        width: 57px;
    }
    
    .table.month-payroll-list tr > td:nth-child(2){
        width: 57px;
    }
    
    .table.month-payroll-list tr > th:nth-child(3){
        width: 118px;
    }
    
    .table.month-payroll-list tr > td:nth-child(3){
        width: 118px;
    }
    
    .table.month-payroll-list tr > th:nth-child(4){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(4){
        width: 100px;
    }
    
    .table.month-payroll-list tr > th:nth-child(5){
        width: 57px;
    }
    
    .table.month-payroll-list tr > td:nth-child(5){
       width: 57px;
    }
    
    .table.month-payroll-list tr > th:nth-child(6){
        width: 122px;
    }
    
    .table.month-payroll-list tr > td:nth-child(6){
       width: 122px;
    }
    
    .table.month-payroll-list tr > th:nth-child(7){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(7){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(7):hover{
       background: ${props => props.theme.data.light.colors.White};
    }
 
    
   .table.month-payroll-list tr > th:nth-child(8){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(8){
        width: 100px;
    }
    
    .table.month-payroll-list tr > th:nth-child(9){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(9){
        width: 100px;
    }
    
      .table.month-payroll-list tr > th:nth-child(10){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(10){
        width: 100px;
    }
    
    .table.month-payroll-list tr > th:nth-child(11){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(11){
        width: 100px;
    }
    
    .table.month-payroll-list tr > th:nth-child(11){
        width: 100px;
    }
    
    .table.month-payroll-list tr > td:nth-child(11){
        width: 100px;
    }
    
    .table.month-payroll-list tr > th:nth-child(12){
        width: 98px;
    }
    
    .table.month-payroll-list tr > td:nth-child(12){
        width: 98px;
    }
    
    .table.month-payroll-list tr > th:nth-child(13){
        width: 125px;
    }
    
    .table.month-payroll-list tr > td:nth-child(13){
        width: 125px;
    }
    
    .table.month-payroll-list tr > th:nth-child(14){
        width: 98px;
    }
    
    .table.month-payroll-list tr > td:nth-child(14){
        width: 98px;
    }
    
    .table.month-payroll-list tr > th:nth-child(15){
        width: 125px;
    }
    
    .table.month-payroll-list tr > td:nth-child(15){
        width: 125px;
    }
    
    .smoke-border-right{
        border-right: 1px solid ${props => props.theme.data.light.colors.Smoke};
    }
    .smoke-border-right:after{
        content: "";
        position: absolute;
        background: ${props => props.theme.data.light.colors.Smoke};
        width: 1px;
        height: 1px;
        right: -1px;
        top: -1px;
        z-index: 9;
    }
`;
