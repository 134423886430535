import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import { Button } from 'components/styles';
import { StateContext } from 'context/DataContext';

const NavigationButtonWithCheckUnSaved = withRouter(({ children, buttonTheme, history, action, navigation, params, query, ...props }) => {
    const theme = useContext(ThemeContext);
    const {unSavedChanges} = useContext(StateContext);

    return (

    <ThemeProvider theme={theme}>
        {
            children ? <>
                      <span
                        onClick={() => {
                            action && action();
                            !unSavedChanges && history.push({
                                pathname: `/${navigation}`,
                                search: query && `?payroll=${query}`,
                                state: params && { params: params }
                            });
                        }}
                    >
              {children}
            </span>
            </> :
                <Button
                    type="button"
                    buttonTheme={buttonTheme}
                    onClick={() => {
                        action && action();
                        !unSavedChanges && history.push({
                            pathname: `/${navigation}`,
                            search: query && `?payroll=${query}`,
                            state: params && { params: params }
                        });
                    }}
                >{props.label}</Button>
       
        }

    </ThemeProvider>
    );
});

export default NavigationButtonWithCheckUnSaved;
