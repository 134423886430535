import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  SubmitClose,
  SubmitForm,
  SubmitPayRollContainer,
  SubmitPayRollContainerBG,
  SubmitPayRollContainerInner,
  SubmitPayRollTextFinalStep,
  SubmitPayRollText,
  SubmitPayRollHeader,
  SubmitPayRollLabel,
  SubmitTextArea,
  SubmitButton,
  ConfirmButtonsArea,
} from "./styles";
import { DispatchContext, StateContext } from "context/DataContext";
import { BigClose } from "commons/svgs";
import ActionSubmitButton from "commons/ui/ActionSubmitButton";
import ActionConfirm from "commons/ui/ActionConfirm";
import ActionCancel from "commons/ui/ActionCancelButton";
import {
  approvePayroll,
  rejectPayroll,
  submitPayroll,
  processPayroll,
} from "services/services";
import { useAuthDataContext } from "context/AuthContext";
import { convertDateMonthYear } from "helpers/date";

const SubmitPayroll = () => {
  const dispatchDataContext = useContext(DispatchContext);
  const {
    authData: { privileges, username },
  } = useAuthDataContext();
  const stateContext = useContext(StateContext);
  const { register, handleSubmit } = useForm();

  const [step, setStep] = useState(1);
  const [finalData, setFinalData] = useState({});

  const onSubmit = (data) => {
    setStep(2);
    setFinalData({ ...data, ...stateContext.singleMonthPayRoll });
  };

  const onConfirm = () => {
    if (privileges === "Vessel") {
      submitPayroll(
        finalData[0].idfpayroll,
        finalData.submitComment,
        username
      ).then((data) => {
        if (data !== "Submitted") {
          alert("Something Went Wrong");
        }
        setStep(1);
        dispatchDataContext({ type: "submit", payload: false });
        window.location.href = `/crew-payroll`;
      });
    }

    if (privileges === "Accounting" && stateContext.reject) {
      rejectPayroll(
        finalData[0].idfpayroll,
        finalData.submitComment,
        username
      ).then((data) => {
        console.log(data);
        if (data !== "Rejected") {
          alert("Something Went Wrong");
        }
        setStep(1);
        dispatchDataContext({ type: "submit", payload: false });
        dispatchDataContext({ type: "reject", payload: false });
        window.location.href = `/accounting`;
      });
    }

    if (privileges === "Accounting" && stateContext.approve) {
      approvePayroll(
        finalData[0].idfpayroll,
        finalData.submitComment,
        username
      ).then((data) => {
        console.log(data);
        if (data !== "Approved") {
          alert("Something Went Wrong");
        }
        setStep(1);
        dispatchDataContext({ type: "approve", payload: false });
        dispatchDataContext({ type: "submit", payload: false });
        window.location.href = `/accounting`;
      });
    }

    if (privileges === "Accounting" && stateContext.process) {
      processPayroll(
        finalData[0].idfpayroll,
        finalData.submitComment,
        username
      ).then((data) => {
        console.log(data);
        if (data !== "Processed") {
          alert("Something Went Wrong");
        }
        setStep(1);
        dispatchDataContext({ type: "process", payload: false });
        dispatchDataContext({ type: "submit", payload: false });
        window.location.href = `/accounting`;
      });
    }
  };

  const onCancel = () => {
    setStep(1);
    dispatchDataContext({ type: "submit", payload: false });
    dispatchDataContext({ type: "process", payload: false });
    dispatchDataContext({ type: "approve", payload: false });
    dispatchDataContext({ type: "reject", payload: false });
  };

  return stateContext["submit"] ? (
    <SubmitPayRollContainer>
      <SubmitPayRollContainerBG />
      {step === 1 ? (
        <SubmitPayRollContainerInner>
          <SubmitClose onClick={onCancel}>
            <BigClose />
          </SubmitClose>
          <SubmitPayRollHeader>
            {resolveTextStepOnePayroll(stateContext)}
          </SubmitPayRollHeader>
          <SubmitForm onSubmit={handleSubmit(onSubmit)}>
            <SubmitPayRollLabel>Comment</SubmitPayRollLabel>
            <SubmitTextArea
              {...register("submitComment")}
              onChange={(e) => console.log(e)}
              rows="4"
              cols="50"
              placeholder={"Type any comments for the reviewer here..."}
            />
            <SubmitButton>
              <ActionSubmitButton label={"SUBMIT"} />
            </SubmitButton>
          </SubmitForm>
        </SubmitPayRollContainerInner>
      ) : (
        <SubmitPayRollContainerInner>
          <SubmitClose onClick={onCancel}>
            <BigClose />
          </SubmitClose>
          <SubmitPayRollTextFinalStep>
            <SubmitPayRollText>
              {resolveTextPayroll(stateContext)}
            </SubmitPayRollText>
            <ConfirmButtonsArea>
              <ActionConfirm action={onConfirm} />
              <ActionCancel action={onCancel} />
            </ConfirmButtonsArea>
          </SubmitPayRollTextFinalStep>
        </SubmitPayRollContainerInner>
      )}
    </SubmitPayRollContainer>
  ) : null;
};

export default SubmitPayroll;

const resolveTextPayroll = (stateContext) => {
  if (stateContext.process) {
    return `Please confirm process of payroll to IYC. Once processed payroll will be processed and changes can no longer be made.`;
  }
  if (stateContext.approve) {
    return `Please confirm approve of payroll to IYC.  Once processed payroll will be approved and changes can no longer be made.`;
  }
  if (stateContext.reject) {
    return `Please confirm reject of payroll to IYC.`;
  }
  return `Please confirm submission of payroll to IYC. Once submitted payroll will be checked and processed and changes can no longer be made.`;
};

const resolveTextStepOnePayroll = (stateContext) => {
  let MonthYear = convertDateMonthYear(
    `1/${stateContext.singleMonthPayRoll[0].month}/${stateContext.singleMonthPayRoll[0].year}`
  );

  if (sessionStorage.getItem("month")) {
    MonthYear = convertDateMonthYear(
      JSON.parse(sessionStorage.getItem("month"))[0].datedue
    );
  }

  if (stateContext.process) {
    return `Process Payroll for ${MonthYear}`;
  }
  if (stateContext.approve) {
    return `Approve Payroll for ${MonthYear}`;
  }
  if (stateContext.reject) {
    return `Reject Payroll for ${MonthYear}`;
  }
  return `Submit Payroll for ${MonthYear}`;
};
