import { createGlobalStyle } from 'styled-components';

export const GlobalClasses = createGlobalStyle`
 
     .table-fix-layout{
       table-layout: fixed;
       width: 100%;
     }
     
     .overflow-visible{
       overflow: visible !important;
     }
     
    .no-pointer{
      pointer-events: none !Important; 
      cursor: not-allowed;
    }
    
     textarea:focus, input:focus{
        // border-color: rgba(20, 88, 236, 0.8);
        // box-shadow: 0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(20 88 236 / 60%);
        outline: 0 none;
        border-color: rgb(163, 177, 214);
        // box-shadow: inset 0 0 0 0.2rem rgba(163, 177, 214, 0.25), 0 0 0 0.2rem rgba(163, 177, 214, 0.25);
        
        -webkit-box-shadow: inset 2px 2px 2px 0px #dddddd;
        -moz-box-shadow: inset 2px 2px 2px 0px #dddddd;
        box-shadow: inset 2px 2px 2px 0px #dddddd;
        overflow: auto;
     }

     .close-datepicker-wrap{     
        position: fixed;
        z-index: 0;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
     }

     .datepicker-calendar{
       position: absolute;
       z-index: 1000;
       background: #fff;
       top: 64px;
       border-color: rgb(9, 14, 54) !important;
       box-shadow: 0 0 5px rgb(9 14 54) !important; 
     }
     .datepicker-calendar-big{
         top: -87px;
         margin-left: 124px;
     }
    
     .datepicker-wrp{
         position: relative;
         input{
           pointer-events: none;
         }
         
         .picker-date-input{
           opacity: 0;
           height: 0;
         }
 
         .picker-date{
            width: 122px;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 17px;
            -webkit-letter-spacing: 0.01em;
            -moz-letter-spacing: 0.01em;
            -ms-letter-spacing: 0.01em;
            letter-spacing: 0.01em;
            color: #090E36;
            border: 1px solid #C0CCDB;
            cursor: pointer;
            padding: 10px 0;
            text-align: center;
            &.picker-date-big{
                  width: 187px;
                  background: #fff;
                  float: left;
               > span{
                    float: left;
                    margin-left: 15px;
               }
            }
         }
         
         ._error_field {
            border: 1px solid red;
         }
      }   
 

    .month-payroll-list  .expenses_:hover +  tr    {
      background: #EBEBFF !important;
    }
   
     input {
        position: relative;
        width: 100%;
     }
     
     .sm-pad-right{
        padding-right: 10px;
     }

     input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
     }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield; 
    }
    
    input[type=date] {
       background-color: #fff;
    }
    
    .table.month-payroll-list input:read-only {
      background-color: #F9FAFC;
    }
    
    .table.month-payroll-list textarea:read-only {
      background-color: #F9FAFC;
    }
    
    .table.month-payroll-list  textarea:read-only {
      cursor: not-allowed;
    }
    
    .table.month-payroll-list  input:read-only {
      cursor: not-allowed;
 
    }
    ._error_field{
      border: 1px solid red;
    }
    
    #root{
        // height: 100vh;
        // overflow: hidden;
        position: relative;
        display: block;
        float: left;
        width: 100%;
        min-height: 100vh;
    }
    .w-100{
      width: 100%;
    }
    .dev-opacity{
     
        position: fixed;
        z-index: 9;
        /* background: red; */
        width: 100vw;
        height: 100vh;
        top: 0;
        pointer-events: none;
        background: #090E36;
        opacity: 0.4;
    }
    
    .pos-rel{
      position: relative;
    }
    
    .m-w > div{
     width: 53px;
    }
    .m-w {
     width: 53px;
    }
    
    .f-r{
      float: right !Important;
    }
    
    .custom-scrollbars>div:last-child {
        opacity: 0;
        transition: opacity 200ms ease;
    }
    
    .custom-scrollbars:hover>div:last-child {
        opacity: 1 !important;
    }

    .text-lowercase{
      text-transform: lowercase !important;
    }
    .hide{
      display: none;
    }
    
    .text-upper{
      text-transform: uppercase;
    }
    
    .text-left{
       text-align: left;
    }

    .text-right{
       text-align: right;
    }
    
    .text-center{
       text-align: center;
    }
    
    .body-primary-l{
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0px;
    } 

    .body-secondary-l{
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-align: left;
    } 
    
    .body-secondary-b{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-align: left;
    } 
    
    .body-secondary-sm{
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    } 
    
     .table-heading-sm{
        font-family: SF Pro Display;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 0.01em;
    } 
    
    .table-secondary-sm{
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0.01em;
    } 
    
    .table-secondary-td{
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 17px;
        letter-spacing: 0.01em;
    }
    .table-total{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.01em;
        text-align: right;
    }
    
    .big-heading{
        font-family: SF Pro Display;
        font-size: 28px;
        font-style: normal;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0.01em;
    } 
 
    h1{
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: left;
    }
    
    h2{
        font-family: SF Pro Display;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
    }
   
    .no-border{
      border: 0!important;
    }
    
    .border-top{
       border-top: 1px solid #E5E9F3;
    }
    
    .no-border-right{
      border-right: 0 !important;
    }
   
    .action-button{
       padding: 0;
       display: block;
       float: left;
       width: 85px;
       height: 31px;
       top: 10px;
    }
    
    .table{
        width: 100%;
    }
    
    .table tr{
        border-bottom: 1px solid #E5E9F3;
    }
   
`;
