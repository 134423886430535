import React, {useContext, useEffect} from 'react';
import { StateContext, DispatchContext } from 'context/DataContext';
import {useAuthDataContext} from "context/AuthContext";
import {getPayRollList} from 'services/services';
import {convertDateYYYYMMDD} from 'helpers/date'

import PayrollListView from './PayrollListView'

const PayRollListContainer = () => {
    
    const dispatchDataContext = useContext(DispatchContext);
    const stateContext = useContext(StateContext);
    const {authData:{yachtid,username}} = useAuthDataContext()

    useEffect(() => {
        getPayRollList(yachtid,username).then(data => {
            dispatchDataContext({ type: 'payRollList', payload: sortingPayroll(data.payroll) })
        }).catch((err) => {
            console.error(err);
        })
    }, [dispatchDataContext,username,yachtid]);

    return (
      <PayrollListView payRollList={stateContext['payRollList']}/>
    );
};

function sortingPayroll (payroll) {
    let sortValues = {
        approved: [],
        inprogress: [],
        final: [],
        submitted: []
    }

    let SortPayroll = payroll.reduce((finalObj,item)=>{
        if(item.status === 'Approved'){
            finalObj.approved.push(item)
            return finalObj
        }
        if(item.status === 'Submitted'){
            finalObj.submitted.push(item)
            return finalObj
        }
        if(item.status === 'In Progress'){
            finalObj.inprogress.push(item)
            return finalObj
        }

        if(item.status === 'Processed'){
            finalObj.final.push(item)
            return finalObj
        }

        return  finalObj
    },sortValues)

    return  [
        ...CompareByDate(SortPayroll.inprogress),
        ...CompareByDate(SortPayroll.submitted),
        ...CompareByDate(SortPayroll.approved),
        ...CompareByDate(SortPayroll.final)
    ]
}

function CompareByDate(array){

    return array.sort(function(a,b){
        return new Date(convertDateYYYYMMDD(b.datedue)) - new Date(convertDateYYYYMMDD(a.datedue));
    });
}

export default PayRollListContainer;
