import {useAuthDataContext} from "context/AuthContext";
import { Avatar, Logo, Search } from 'commons/svgs';
import {HeaderContainer,LogoSection,SearchSection,AvatarSection,AvatarName,Border,MaxContainer,HeaderFull,LogOutDiv} from './styles'
import {useState} from "react";

const Header = () => {

  const { authData: {name}, onLogout } = useAuthDataContext();

  const [show,setShow] = useState(false)

  return (
    <>
      <HeaderFull>
        <MaxContainer>
          <HeaderContainer>
            <LogoSection>
              <Logo />
            </LogoSection>
            <SearchSection>
              <Search />
            </SearchSection>
            <AvatarSection
                onMouseLeave={ () => setShow(!show)}
                onMouseEnter={ () => setShow(!show)}>
              <Border />
              <AvatarName>Hi, {name}</AvatarName>
              <Avatar />
              {
                show &&
                <LogOutDiv onClick={onLogout}>
                   Logout
                </LogOutDiv>
              }
            </AvatarSection>
          </HeaderContainer>
        </MaxContainer>
      </HeaderFull>
    </>
  );
};

export default Header;
