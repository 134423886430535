import { ListTh } from '../../commons/ui/styles';

const TableHeadMonthPayroll = ({privileges}) => {
  return (
    <tr className={'border-top'}>

        <ListTh className={'table-secondary-sm text-upper text-left'}>Full name</ListTh>

        <ListTh className={'table-secondary-sm text-upper'}>Notes</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>monthly</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>daily</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>days</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>gross salary</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>expenses</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>deductions</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>bonus</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>gratuties</ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>flights</ListTh>

        <ListTh className={'table-secondary-sm'}>
          <div className={'unpaid-leave'}>
            Unpaid Leave
          </div>
        </ListTh>

        <ListTh className={'table-secondary-sm text-upper text-center'}>total</ListTh>

        {
            privileges !== 'Vessel' &&
            <>
                <ListTh className={'table-secondary-sm'}>
                    <div className={'unpaid-leave'}>
                       FEES
                    </div>
                </ListTh>

                <ListTh className={'table-secondary-sm text-upper text-center'}>final total</ListTh>
            </>
        }

    </tr>
  );
};

export default TableHeadMonthPayroll;
