import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DispatchContext, StateContext } from "context/DataContext";
import { useAuthDataContext } from "context/AuthContext";

import {
  getCrewList,
  getPayRollList,
  getSingleMonthPayRoll,
} from "../services/services";

import { convertDateYYYYMMDD } from "helpers/date";

export const useMonthPayrollList = (monthPayrollID, newPayroll) => {
  const dispatchDataContext = useContext(DispatchContext);
  const stateContext = useContext(StateContext);
  const {
    authData: { username, privileges },
  } = useAuthDataContext();
  const [state, setState] = useState({ notLoaded: true });
  const [isEditable, setIsEditable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setState({ notLoaded: true });

      const Yachtid = localStorage.getItem("yachtid");
      const [data, r1, r2] = await Promise.all([
        getSingleMonthPayRoll(monthPayrollID, username),
        getPayRollList(Yachtid, username),
        getCrewList(Yachtid, username),
      ]);

      if (!data || !r1 || !r2) {
        return history.push("/");
      }

      dispatchDataContext({
        type: "all",
        payload: {
          payroll: sortingPayroll(r1.payroll),
          crew: r2.crew,
          singleMonth: updateSinglePayRollData(data.payrollitem, r2.crew),
        },
      });

      const isMonthSubmitted =
        r1.payroll && r1.payroll.filter((month) => month.id === monthPayrollID);
      setIsEditable(editable(privileges, isMonthSubmitted[0]));
      return setState({ notLoaded: false });
    }
    getData();
  }, []);

  return [stateContext, state, isEditable];
};

const updateSinglePayRollData = (payrollitems, crewList) => {
  return payrollitems.map((payroll) => {
    return populateSinglePayRollData(crewList, payroll);
  });
};

const populateSinglePayRollData = (array, payroll) => {
  let objIndex = array.findIndex((obj) => obj.id === payroll.idfcrew);
  console.log(array);
  console.log(payroll);
  payroll.name = array[objIndex].name;
  payroll.surname = array[objIndex].surname;
  payroll.full_name = array[objIndex].name + " " + array[objIndex].surname;
  payroll.startdate = array[objIndex].startdate;
  // payroll.enddate = array[objIndex].enddate;
  payroll.leaveannualdays = array[objIndex].leaveannualdays;
  payroll.leavedailyaccrualrate = array[objIndex].leavedailyaccrualrate;
  payroll.leavedaterenew = array[objIndex].leavedaterenew;
  return payroll;
};

const editable = (privileges, data) => {
  if (privileges === "Vessel" && data.issubmitted === true) {
    return true;
  }

  if (privileges === "Accounting" && data.status === "In Progress") {
    return true;
  }

  if (privileges === "Accounting" && data.status === "Approved") {
    return true;
  }

  if (privileges === "Accounting" && data.status === "Processed") {
    return true;
  }

  return false;
};

function sortingPayroll(payroll) {
  let sortValues = {
    approved: [],
    inprogress: [],
    final: [],
    submitted: [],
  };

  let SortPayroll = payroll.reduce((finalObj, item) => {
    if (item.status === "Approved") {
      finalObj.approved.push(item);
      return finalObj;
    }
    if (item.status === "Submitted") {
      finalObj.submitted.push(item);
      return finalObj;
    }
    if (item.status === "In Progress") {
      finalObj.inprogress.push(item);
      return finalObj;
    }

    if (item.status === "Processed") {
      finalObj.final.push(item);
      return finalObj;
    }

    return finalObj;
  }, sortValues);

  return [
    ...CompareByDate(SortPayroll.inprogress),
    ...CompareByDate(SortPayroll.submitted),
    ...CompareByDate(SortPayroll.approved),
    ...CompareByDate(SortPayroll.final),
  ];
}

function CompareByDate(array) {
  return array.sort(function (a, b) {
    return (
      new Date(convertDateYYYYMMDD(b.datedue)) -
      new Date(convertDateYYYYMMDD(a.datedue))
    );
  });
}
