import React, { createContext, useState, useEffect, useContext } from 'react';
import { getUser } from 'services/services';
import { useGoogleLogout } from 'react-google-login'

export const AuthDataContext = createContext(null);

const initialAuthData = {};

const onLogoutSuccess = () => {
    console.log("logout success");
}

const onFailure = () => {
    console.log("logout failure");
}

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);

  const { signOut } = useGoogleLogout({
        clientId: '628445088392-0iom8cdaenepqgfig9cls80t4ti1krib.apps.googleusercontent.com',
        onLogoutSuccess: onLogoutSuccess,
        onFailure: onFailure
  })

  useEffect(() => {
    getUser().then(data => setAuthData(
        {
          user: data.user,
          userid: data.userid,
          username: data.username,
          privileges: data.privileges,
          name: data.name,
          yachtname: data.yachtname,
          yachtid: data.yachtid
        }));
  }, []);

  const onLogout = () => {
    delete_cookie('IYCtoken','/', process.env.REACT_APP_COOKIE_DOMAIN)
    signOut()
    setAuthData(initialAuthData);
    return window.location.href = `/login`;
  }

  const onLogin = newAuthData => setAuthData(newAuthData);

  return <AuthDataContext.Provider value={{ authData, onLogin, onLogout }} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;

function delete_cookie( name, path, domain ) {
  if( get_cookie( name ) ) {
    document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain)?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function get_cookie(name){
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
}