import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import { Button } from 'components/styles';

const NavigationButton = withRouter(({ children, buttonTheme, history, action, navigation, params, query,yachtid, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      {(children && (
        <span
          onClick={() => {
            yachtid && localStorage.setItem('yachtid',yachtid)
            action && action();
            history.push({
              pathname: `/${navigation}`,
              search: query && `?payroll=${query}`,
              state: params && { params: params }
            });

          }}
        >
          {children}
        </span>
      )) || (
        <Button
          type="button"
          buttonTheme={buttonTheme}
          onClick={() => {
            yachtid && localStorage.setItem('yachtid',yachtid)
            action && action();
            history.push({
              pathname: `/${navigation}`,
              search: query && `?payroll=${query}`,
              state: params && { params: params }
            });
          }}
        >
          {props.label}
        </Button>
      )}
    </ThemeProvider>
  );
});

export default NavigationButton;
