import {useState} from 'react'
import styled from 'styled-components';

const ToolTipWrapper = styled.div`
   display: flex;
`;

const ToolTipTriangle = styled.div`
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -11px;
    z-index: 999999999999;
    > span{
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 12px solid #fff;
        float: left;
    }
`
const ToolTipComment = styled.div`
    position: absolute;
    z-index: 9;
    bottom: 26px;
    background: #ffffff;
    box-shadow: 0px 5px 22px rgb(0 0 0 / 17%);
    border-radius: 10px;
    display: block;
    min-width: 170px;
    max-width: 500px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #323b43;
    padding: 10px 13px 10px 13px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    > div{
      display: block;
      float: left;
      width: 100%;
      text-align: center;
    }
`;

const Tooltip = ({text,renderProp}) => {
    
    const [show,setShow] = useState(false)
    
    const actionShow = (value) => {
        if(value){
            const table = document.getElementsByTagName('table')
            table[0].classList.add('overflow-visible')
        }else{
            const table = document.getElementsByTagName('table')
            table[0].classList.remove('overflow-visible')
        }
        setShow(value)
    }

    return <ToolTipWrapper>
        <div onMouseEnter={() => actionShow(true)} onMouseLeave={() => actionShow(false)}>
            {renderProp && renderProp}
            {
                show && text &&
                <>
                    <ToolTipComment>
                        <div>{text}</div>
                    </ToolTipComment>
                    <ToolTipTriangle>
                        <span></span>
                    </ToolTipTriangle>
                </>
            }
        </div>
    </ToolTipWrapper>
}

export default Tooltip