import React, {useContext, useState} from "react";
import {getNewPayRollId} from "services/services";
import {Redirect} from "react-router-dom";
import styled from 'styled-components';
import {PlusSmall} from "commons/svgs";
import {useAuthDataContext} from "context/AuthContext";
import {DispatchContext} from "context/DataContext";

const CreateNewPayRollContainer = styled.div`
  padding-top: 26px;
  padding-right: 78px;
  text-align: right;
`;

const CreateNewPayRollButton = styled.button`
   ${props => props.theme.data.light.typography['table-sd']}  
   background: ${props => props.theme.data.light.colors.DirtySnow};
   color:  ${props => props.theme.data.light.colors.NightSky};
   border: 1px solid ${props => props.theme.data.light.colors.DirtySnow};
   padding: 10px 13px 9px 33px;
   cursor: pointer;
   
   > span: nth-child(1){
      position: relative;
      left: -15px;
      top: 0px;
   }
   &:hover{
      background: #c8d0e4;
      border: 1px solid #c8d0e4;
   }
`;

const CreateNewPayRoll = () => {
    const dispatchDataContext = useContext(DispatchContext);
    const [state,setState] = useState(false)
    const {authData:{yachtid,username}} = useAuthDataContext()
    const newPayRollId = () => {
        dispatchDataContext({ type: 'loader', payload: true })
        getNewPayRollId(yachtid,username).then(data => {
            dispatchDataContext({ type: 'loader', payload: false })
            setState(data.payrollid)
            // console.log(data.payrollid)
            // stateContext.crewList.forEach( crew => {
            //     newPayrollArr.push(populatePayroll(crew,data.payrollid))
            //
            // })
        })
    }

    if(!state){
        return (
            <CreateNewPayRollContainer>
                <CreateNewPayRollButton onClick={() => newPayRollId()}>
                    <span>
                        <PlusSmall/>
                    </span>
                    <span>
                        ADD PAYROLL
                    </span>
                </CreateNewPayRollButton>
            </CreateNewPayRollContainer>
        )
    }

    return(
        <Redirect
            to={{
                pathname: "/month-payroll",
                search: `?payroll=${state}`,
                state: 'newPayroll'
            }}
        />
    )

}

export default CreateNewPayRoll

// const populatePayroll = (crew,payrollId) => {
//
//     return {
//         "bonus": 0,
//         "comments": "",
//         "currency": crew.currency,
//         "dailyrate": crew.dailyrate,
//         "daysoff": 0,
//         "daysworked": 0,
//         "deductions": 0,
//         "enddate": crew.enddate,
//         "finaltotal": 0,
//         "flights": 0,
//         "fullmonth": false,
//         "gratuities": 0,
//         "grosssalary": 0,
//         "id": "0676BCDA-E047-444B-96A7-EC25341BEDF1",
//         "idfcrew": crew.id,
//         "idfpayroll": payrollId,
//         "isprocessed": false,
//         "lastdatecrew": "",
//         "leavedailyaccrualrate": 0,
//         "leavedaysaccrued": 0,
//         "leavedaystaken": 0,
//         "leavemonthendbalance": 0,
//         "leavemonthstartbalance": 0,
//         "month": "4",
//         "monthlysalary": crew.monthlyrate,
//         "total": 0,
//         "totalexpenses": 0,
//         "type": "",
//         "startdate": crew.startdate,
//         "unpaidleave": 0,
//         "yacht": crew.yacht,
//         "year": 10,
//         "name": crew.name,
//         "surname": crew.surname,
//         "full_name": crew.name + ' ' + crew.surname
//     }
// }