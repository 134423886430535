import { DispatchContext } from 'context/DataContext';
import React, { useContext } from 'react';
import {getMonthAndYearAndDates,formatDate} from "../helpers/date";

export const useSinglePayRow = (singleMonthPayRoll, setValue, id) => {
  const dispatchDataContext = useContext(DispatchContext);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const inputChange = ({ target }, name, type) => {
    
    let { value } = target;
    dispatchDataContext({ type: 'unSavedChanges', payload: true });

    console.log(value)
    value = value.replace(/,/g, '.').replace(/[^.\-\d]/g, '').replace(/^0+/, '');

    console.log(value)
    console.log(makeNegativeNumber(value))

    if (value === '') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, name, 0);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue(name, 0);
    }

    if (value === '-') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, name, 0);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue(name, value);
    }

    if (type === 'negative') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, name, makeNegativeNumber(value));
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return value.includes("-") ? setValue(name, value) : setValue(name, '-' + value);
    }

    if (type === 'select') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, name, JSON.parse(value));
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue(name, JSON.parse(value));
    }

    const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, name, parseFloat(value));
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
    forceUpdate();
    return setValue(name, value);
  };

  const inputChangeDates = ({ target },max) => {

    let SinglePayrollItem = findByIdAndReturnPayRoll(singleMonthPayRoll, id)

    if(SinglePayrollItem[0].enddate){
      let [dd,mm,yyyy] = getMonthAndYearAndDates(SinglePayrollItem[0].enddate)
      if(mm === parseInt(SinglePayrollItem[0].month) && yyyy === parseInt(SinglePayrollItem[0].year)){
        max = dd
      }
      if(mm < parseInt(SinglePayrollItem[0].month) && yyyy === parseInt(SinglePayrollItem[0].year)){
        max = 0
      }
    }

    const { value } = target;
    dispatchDataContext({ type: 'unSavedChanges', payload: true });
    if (value > max) {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'daysworked', max);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue('daysworked', max);
    }
    if (value < 0 ||  value === '') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'daysworked', 0);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue('daysworked', 0);
    }
    const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'daysworked', parseFloat(value));
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
    forceUpdate();
    return setValue('daysworked', parseInt(value));
  };

  const inputTextArea = ({ target }) => {
    const { value } = target;
    dispatchDataContext({ type: 'unSavedChanges', payload: true });
    const newPayRoll = updateNotesSinglePayRoll(singleMonthPayRoll, id, 'comments', value);
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
    forceUpdate();
    return setValue('notes', value);
  };

  const inputDatesArea = ({ target }, name) => {

    const { value } = target;
    dispatchDataContext({ type: 'unSavedChanges', payload: true });
    let newPayRoll

    let SinglePayrollItem = findByIdAndReturnPayRoll(singleMonthPayRoll, id)

    let [dd,mm,yyyy] = getMonthAndYearAndDates(value)
    if(mm === parseInt(SinglePayrollItem[0].month) && yyyy === parseInt(SinglePayrollItem[0].year)){
         newPayRoll = updateNotesSinglePayRoll(singleMonthPayRoll, id, name, formatDate(value), parseInt(dd));
         setValue('daysworked', dd);
    }else{
        newPayRoll = updateNotesSinglePayRoll(singleMonthPayRoll, id, name, formatDate(value));
    }

    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
    forceUpdate();

    console.log('set Value date')
    console.log(value)

    return setValue(name, formatDate(value));
  };

  const inputChangeDaysOff = ({ target },max) => {
    const { value } = target;
    let madDates = 31

    console.log('test')

    if (parseInt(value) >  madDates) {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'leavedaystaken', madDates);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue('leavedaystaken', madDates);
    }
    if (value === '') {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'leavedaystaken', 0);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue('leavedaystaken', 0);
    }
    if (parseInt(value) < -30) {
      const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'leavedaystaken', -30);
      dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
      forceUpdate();
      return setValue('leavedaystaken', -30);
    }
    const newPayRoll = findByIdSinglePayRoll(singleMonthPayRoll, id, 'leavedaystaken', parseInt(value));
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
    forceUpdate();
    return setValue('leavedaystaken', parseInt(value));
  };

  return {
    inputChange,
    inputChangeDates,
    inputTextArea,
    inputDatesArea,
    inputChangeDaysOff,
    forceUpdate,
  };
};

const makeNegativeNumber = number => {
  return -Math.abs(parseFloat(number));
};

// const makeNegativeNumber = number => {
//   return -Math.abs(Number(number));
// };


const calculateTotal = (payroll) => {
  return payroll.bonus + payroll.gratuities + payroll.totalexpenses + payroll.grosssalary + payroll.flights + payroll.unpaidleave + -Math.abs(parseFloat(payroll.deductions))
};

const calculateGrossSalary = (payroll) => {
  if(payroll.daysworked === payroll.maxdaysmonth) return payroll.monthlysalary
  if(payroll.fullmonth) return payroll.monthlysalary
  return payroll.daysworked * payroll.dailyrate
}

const findByIdAndReturnPayRoll = (array, id) => {
  return array.filter(payroll => {
    if (payroll.id === id) {
      return payroll;
    }
    return false
  });
};

const findByIdSinglePayRoll = (array, id, name, value) => {
  return array.map(payroll => {
    if (payroll.id === id) {
      payroll[name] = value;
      payroll['grosssalary'] = calculateGrossSalary(payroll)
      payroll['total'] = calculateTotal(payroll);
      payroll['finaltotal'] = calculateTotal(payroll) + payroll.fees;
    }
    return payroll;
  });
};

const updateNotesSinglePayRoll = (array, id, name, value, daysWorked) => {
  return array.map(payroll => {
    if (payroll.id === id) {
      payroll[name] = value;
      if(daysWorked){
        payroll['daysworked'] = daysWorked
        payroll['grosssalary'] = calculateGrossSalary(payroll)
        payroll['total'] = calculateTotal(payroll);
        payroll['finaltotal'] = calculateTotal(payroll) + payroll.fees;
      }
    }
    return payroll;
  });
};
