import React, { useContext } from 'react';
import { DispatchContext } from 'context/DataContext';
import { useForm, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { useSinglePayRow } from 'hooks/useSinglePayRow';
import PayRollRow from './PayrollRow/PayRollRow'
import Expenses from './Expenses/Expenses'
import Notes from './Notes/Notes'
import {useAuthDataContext} from "context/AuthContext";

const isEqual = (prevProps, nextProps) => {
  if (nextProps.openNotes !== prevProps.openNotes) {
    return false;
  }
  if (nextProps.classAtr === prevProps.classAtr) {
    return true;
  }
};

const SinglePayRollRow = React.memo(({ payroll, index, data, classAtr,isEditable,openNotes,actionSetNotes}) => {
  const [openExpenses, setOpenExpenses] = useState(false);
  const [showDropZone, setShowDropZone] = useState(-1);
  const dispatchDataContext = useContext(DispatchContext);
  const {authData:{privileges}} = useAuthDataContext()

  const defaultValues = {
    expenses:  payroll.expenses || []
  };

  const AddExpenses = () => {
    dispatchDataContext({ type: 'total', payload: false })
    setOpenExpenses(true);
    document.documentElement.style.overflow = "hidden";
  };

  const formMethods = useForm({ defaultValues });

  const { register, setValue, control, watch } = formMethods;

  const fieldArrayMethods = useFieldArray({
    control,
    name: 'expenses',
  });

  const { fields, append, remove } = fieldArrayMethods;

  const watchFieldArray = watch(`expenses`);
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  if(privileges !== 'Accounting' && !isEditable){
    for (let index = 0; index < controlledFields.length; index++) {
 
      if (!controlledFields[index].date || !controlledFields[index].date === '' ) {
        setTimeout(()=>{
          if(document.getElementById(`picker_o_${index}`)){
            document.getElementById(`picker_o_${index}`).click();
          }
        },200)
        break;
      }

      if (!controlledFields[index].currency) {

        setTimeout(()=>{
          if(document.getElementById(`expenses_currency_${index}`)) {
            document.getElementById(`expenses_currency_${index}`).click();
          }
        },200)
        break;
      }

      if (!controlledFields[index].amountincurrency) {
        setTimeout(()=>{
          if(document.getElementById(`amount_${index}`)) {
            document.getElementById(`amount_${index}`).focus();
          }
        },200)
        break;
      }

    }
  }
  
  const {
    inputChange,
    inputChangeDates,
    inputTextArea,
    inputDatesArea,
    forceUpdate,
    inputChangeDaysOff
  } = useSinglePayRow(data, setValue, payroll.id);
  

  const PayRollRowActions = {
    classAtr: classAtr,
    setOpenNotes: actionSetNotes,
    openNotes: openNotes,
    notesActionOpen: actionSetNotes,
    register: register,
    setOpenExpenses: setOpenExpenses,
    openExpenses: openExpenses,
    inputChange: inputChange,
    AddExpenses: AddExpenses,
    inputChangeDates: inputChangeDates
  }

  const ExpensesActions = {
    openExpenses: openExpenses,
    setOpenExpenses: setOpenExpenses,
    classAtr: classAtr,
    register: register,
    setShowDropZone: setShowDropZone,
    showDropZone: showDropZone,
    remove: remove,
    setValue: setValue,
    forceUpdate: forceUpdate
  }

  const NotesActions = {
    register: register,
    openNotes: openNotes,
    classAtr: classAtr,
    inputTextArea: inputTextArea,
    inputDatesArea:  inputDatesArea,
    inputChange: inputChange,
    inputChangeDaysOff: inputChangeDaysOff
  }

  return (
    <React.Fragment key={index + '_row'}>
      <Expenses 
          append={append}
          payroll={payroll}
          fields={fields} 
          actions={ExpensesActions}
          payrolls={data} 
          isEditable={isEditable}
          setOpenExpenses={setOpenExpenses}
          setShowDropZone={setShowDropZone}
          fieldArrayMethods={fieldArrayMethods}
          watch={watch}
      />
      <PayRollRow privileges={privileges} payroll={payroll} actions={PayRollRowActions} isEditable={isEditable} index={index}/>
      <Notes index={index} payroll={payroll} actions={NotesActions} isEditable={isEditable}/>
    </React.Fragment>
  );
}, isEqual);

export default SinglePayRollRow;

