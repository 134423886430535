import {useCallback, useContext, useEffect} from "react";
import {DispatchContext, StateContext} from "context/DataContext";
import {getCrewList, getPayRollList} from "services/services";
import {useAuthDataContext} from "context/AuthContext";
import {convertDateYYYYMMDD} from 'helpers/date'

export const useGetCrewAndPayRoll = () => {
    
    const dispatchDataContext = useContext(DispatchContext);
    const stateContext = useContext(StateContext);
    const {authData:{yachtid,username}} = useAuthDataContext()
    
    const fetchDataPayRoll = useCallback(getPayRollList,[dispatchDataContext])
    const fetchDataCrew = useCallback(getCrewList,[dispatchDataContext])
    
    const fetchAllData = async function(){
        const [r1,r2] = await Promise.all([fetchDataPayRoll(yachtid,username),  fetchDataCrew(yachtid,username)])
        return [r1,r2]
    }

    const fetchData = useCallback(fetchAllData,[fetchDataCrew,fetchDataPayRoll,yachtid,username])

    useEffect(() => {
        fetchData().then(([r1,r2]) => {
            dispatchDataContext({type:'payRollList',payload: sortingPayroll(r1.payroll)})
            dispatchDataContext({ type:'crewList', payload: r2.crew })
        })
    }, [dispatchDataContext,fetchData]);

    return stateContext
}


function sortingPayroll (payroll) {
    let sortValues = {
        approved: [],
        inprogress: [],
        final: [],
        submitted: []
    }

    let SortPayroll = payroll.reduce((finalObj,item)=>{

        if(item.status === 'Approved'){
            finalObj.approved.push(item)
            return finalObj
        }
        if(item.status === 'Submitted'){
            finalObj.submitted.push(item)
            return finalObj
        }
        if(item.status === 'In Progress'){
            finalObj.inprogress.push(item)
            return finalObj
        }

        if(item.status === 'Processed'){
            finalObj.final.push(item)
            return finalObj
        }

        return  finalObj
    },sortValues)

    return  [
        ...CompareByDate(SortPayroll.inprogress),
        ...CompareByDate(SortPayroll.submitted),
        ...CompareByDate(SortPayroll.approved),
        ...CompareByDate(SortPayroll.final)
    ]
}

function CompareByDate(array){
    return array.sort(function(a,b){
        return new Date(convertDateYYYYMMDD(b.datedue)) - new Date(convertDateYYYYMMDD(a.datedue));
    });
}