import React from 'react';
import {Redirect} from "react-router-dom";
import {useAuthDataContext} from "context/AuthContext";

import TableHeadMonthPayroll from './TableHeadMonthPayroll';
import PayRollListContainer from './PayRollListContainer';

import {useMonthPayrollList} from "hooks/useMonthPayrollList";

import { MonthPayRollContainer, MonthPayRollTableCss } from 'components/monthPayrollList/styles';

const MonthPayrollList = ({monthPayrollID,newPayroll}) => {

  const [stateContext,state,isEditable] = useMonthPayrollList(monthPayrollID,newPayroll)

  const {authData:{privileges}} = useAuthDataContext()
    
  if(!state){
       return <Redirect to='/'/>;
  }

  if(state.notLoaded){
       return null
  }

  return (
    <>
      <MonthPayRollTableCss />
      <MonthPayRollContainer>
        {stateContext['singleMonthPayRoll'] && (
          <>
            <table className={'table-fix-layout'}>
              <thead className={'table month-payroll-list table-heading'}>
                <TableHeadMonthPayroll privileges={privileges} />
              </thead>
              <tbody className={'table month-payroll-list'}>
                <PayRollListContainer data={stateContext.singleMonthPayRoll} currency={stateContext.currency} isEditable={isEditable} />
              </tbody>
            </table>
          </>
        )}
      </MonthPayRollContainer>
    </>
  );
};

export default MonthPayrollList;
