import styled, { createGlobalStyle } from 'styled-components';

export const PayRollContainer = styled.div`
  width: ${props => props.width || 'calc(100% - 503px)'};
  float: left;
  padding: ${props => props.padding || '0 78px 0 76px'};
`;

export const TableHeaderSmall = styled.div`
  width: 100%;
  color: ${props => props.theme.data.light.colors.NightSky};
  padding-top: 32px;
  padding-bottom: 10px;
`;

export const Calendar = styled.span`
  padding-right: 14px;
`;

export const Final = styled.span`
  padding-left: 7px;
  position: relative;
  top: -1px;
`;

export const PayRollListTableCss = createGlobalStyle`
    .table.crew-list tr > th:nth-child(1){
        width: 222px;
    }
    .table.crew-list tr > td:nth-child(1){
        width: 222px;
    }
    
    .table.crew-list tr > td:nth-child(3) > div{
      padding: 0;  
      text-transform: capitalize;
      display: block;
      text-align: right;
    }
    
    .table.accounting-list tr > th:nth-child(1){
        width: 216px;
    }
    
    .table.accounting-list tr > td:nth-child(1){
        width: 216px;
    }
    
    .table.accounting-list tr > th:nth-child(2){
        width: 168px;
    }
    
    .table.accounting-list tr > td:nth-child(2){
        width: 168px;
    }
    
    .table.accounting-list tr > th:nth-child(3){
        width: 136px;
    }
    
    .table.accounting-list tr > td:nth-child(3){
        width: 136px;
    }
    
    .table.accounting-list tr > th:nth-child(4){
        width: 129px;
    }
    
    .table.accounting-list tr > td:nth-child(4){
        width: 129px;
    }
    
    .table.accounting-list tr > th:nth-child(5){
        width: 82px;
    }
    
    .table.accounting-list tr > td:nth-child(5){
        width: 82px;
    }
    
    .table.payroll-list tr > th:nth-child(1){
        width: 230px;
    }
    
    .table.payroll-list tr > td:nth-child(1){
        width: 230px;
    }
    
    .table.payroll-list tr > th:nth-child(2){
        width: 136px;
    }
    
    .table.payroll-list tr > td:nth-child(2){
        width: 136px;
    }
    
    .table.payroll-list tr > th:nth-child(3){
        width: 198px;
    }
    
    .table.payroll-list tr > td:nth-child(3){
        width: 198px;
    }
    
    .table.payroll-list tr > th:nth-child(4){
        width: 134px;
    }
    
    .table.payroll-list tr > td:nth-child(4){
        width: 134px;
    }
`;
