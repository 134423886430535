import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import { ActionNext, NextButton, SmallNextArrowSvg } from 'commons/ui/styles';
import { SmallNextArrow } from '../svgs';

const ActionNextButton = ({ action }) => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <ActionNext type="button" onClick={() => action()}>
        <NextButton>
          <SmallNextArrowSvg>
            <SmallNextArrow />
          </SmallNextArrowSvg>
          NEXT
        </NextButton>
      </ActionNext>
    </ThemeProvider>
  );
};

export default ActionNextButton;
