import {useAuthDataContext} from "context/AuthContext";
import {useMonthPayRollTotal} from 'hooks/useMonthPayRollTotal'
import {toLocale} from "helpers/payroll";

import {
    MonthPayRollTableCss,
    PayRollMonthContainer,
    PayRollMonthContainerInner, TableTD,
    TableTH
} from "./styles";

const PayRollMonthTotal = () => {

    const { monthTotal, show } = useMonthPayRollTotal()
    const {authData:{privileges}} = useAuthDataContext()

    return(
        show &&
        <>
            <MonthPayRollTableCss/>
            <PayRollMonthContainer>
                <PayRollMonthContainerInner Maxwidth={privileges !== 'Vessel' ? '1658px' : '1440px'}>
                    <table className={privileges !== 'Vessel' ? 'table total full__' : 'table total'}>
                        <thead>
                        <tr>
                            <TableTH>
                                &nbsp;
                            </TableTH>
                            <TableTH>
                                gross salary
                            </TableTH>
                            <TableTH>
                                &nbsp;
                            </TableTH>
                            <TableTH>
                                expenses
                            </TableTH>
                            <TableTH>
                                deductions
                            </TableTH>
                            <TableTH>
                                bonus
                            </TableTH>
                            <TableTH>
                                gratuties
                            </TableTH>
                            <TableTH>
                                flights
                            </TableTH>
                            <TableTH>
                                unpaid leave
                            </TableTH>
                            <TableTH>
                                Total
                            </TableTH>
                            {
                                privileges !== 'Vessel' &&
                                    <>
                                        <TableTH>
                                           fees
                                        </TableTH>
                                        <TableTH>
                                           final Total
                                        </TableTH>
                                    </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <TableTD>
                                eur
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.grosssalary}
                            </TableTD>
                            <TableTD>
                                &nbsp;
                            </TableTD>
                            <TableTD>
                                <span>€ {monthTotal.eur.totalexpenses}</span>
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.deductions}
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.bonus}
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.gratuities}
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.flights}
                            </TableTD>
                            <TableTD>
                                € {monthTotal.eur.unpaidleave}
                            </TableTD>
                            <TableTD>
                                <em>€ {monthTotal.eur.total}</em>
                            </TableTD>
                            {
                                privileges !== 'Vessel' &&
                                <>
                                    <TableTD>
                                        € {monthTotal.eur.fees}
                                    </TableTD>
                                    <TableTD>
                                        <em>€ {monthTotal.eur.finaltotal}</em>
                                    </TableTD>
                                </>
                            }
                        </tr>
                        <tr>
                            <TableTD>
                                usd
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.grosssalary}
                            </TableTD>
                            <TableTD>
                                &nbsp;
                            </TableTD>
                            <TableTD>
                                <span>$ {monthTotal.usd.totalexpenses}</span>
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.deductions}
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.bonus}
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.gratuities}
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.flights}
                            </TableTD>
                            <TableTD>
                                $ {monthTotal.usd.unpaidleave}
                            </TableTD>
                            <TableTD>
                                <em>$ {monthTotal.usd.total}</em>
                            </TableTD>
                            {
                                privileges !== 'Vessel' &&
                                <>
                                    <TableTD>
                                        $ {monthTotal.usd.fees}
                                    </TableTD>
                                    <TableTD>
                                        <em>$ {monthTotal.usd.finaltotal}</em>
                                    </TableTD>
                                </>
                            }
                        </tr>
                        </tbody>
                    </table>
                </PayRollMonthContainerInner>
            </PayRollMonthContainer>
        </>
    )
}

export default PayRollMonthTotal