import {useState} from "react"

import download from "downloadjs";

import { useExpensesInputHandlers } from 'hooks/useExpensesInputHandlers';
import { downloadFile } from "services/services"
import { dateFormatToYYYYMMDD } from 'helpers/date'
import { AddFile, DeleteFile, DownloadFile } from "commons/svgs";

import Datepicker from 'components/datepicker/Datepicker'
import SelectCurrency from 'components/select/SelectCurrency'
import DropZone from "components/dropZone/DropZone";

import {
    ExpensesAddFile,
    ExpensesAddFileSvg,
    ExpensesContainer,
    ExpensesDeleteFile, 
    ExpensesDeleteFileNameSvg,
    ExpensesDeleteFileSvg,
    ExpensesDiv,
    ExpensesDownloadFile,
    ExpensesDownloadFileSvg, 
    ExpensesDropZone,
    ExpensesFieldNameFile,
    ExpensesInput,
    ExpensesInputDatePicker,
    ExpensesLabel,
    ExpensesTextArea,
    ExpensesDropZoneInner,
    ExpensesSelect
} from "../styles";

const ExpensesContainerRow = ({i,field,payrolls,payroll,fields,actions,payrollId,isEditable,actionDeleteModal,actionDeleteFileModal,AddFileExpenses }) => {

    const downloadExpensesFile = (file) => {
        downloadFile(file).then((data)=>{
            download(data, file)
        })
    }

    const {
        setShowDropZone,
        showDropZone,
        register,
        setValue
    } = actions

    const {
        inputExpenses,
        inputExpensesDates,
        inputExpensesTextArea,
        inputExpensesCurrency
    } = useExpensesInputHandlers(payrolls, setValue, payroll.id);


    const [showCalendar, setShowCalendar] = useState(false)

    const openCalendar = (value) => {
        setShowCalendar(value)
    }

    return(
        <ExpensesContainer className={'pos-rel'} >
            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>DATE</ExpensesLabel>
                }
                <div className={'datepicker-wrp'}>
                    <div className={"datepicker-calendar"}>
                        <Datepicker
                            date={dateFormatToYYYYMMDD(field.date)}
                            fieldName={`expenses_date_${i}`}
                            onChange={inputExpensesDates}
                            showCalendar={showCalendar}
                            openCalendar={openCalendar}
                        />
                    </div>
                    <span id={`picker_o_${i}`} onClick={() => openCalendar(true)}></span>
                    <div id={`picker_${i}`} className={isEditable && 'no-pointer'} onClick={() => openCalendar(!showCalendar)}>
                        <div className={!field.date ? ' picker-date' : 'picker-date'}>
                            {dateFormatToYYYYMMDD(field.date) || 'YYYY-MM-DD'}
                        </div>
                        <div className="picker-date-input">
                            <ExpensesInputDatePicker
                                className={!field.date && '_error_field'}
                                readOnly={isEditable}
                                key={field.id}
                                type="date"
                                {...register(`expenses.${i}.date`)}
                                defaultValue={dateFormatToYYYYMMDD(field.date)}
                            />
                        </div>

                    </div>

                </div>

            </ExpensesDiv>

            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>CURRENCY</ExpensesLabel>
                }

                <span className={isEditable && 'no-pointer'}>
                    <SelectCurrency defaultValue={field.currency} action={inputExpensesCurrency} fieldName={`expenses_currency_${i}` }/>
                </span>

                <ExpensesSelect
                    // className={!field.currency && '_error_field'}
                    key={field.id}
                    {...register(`expenses.${i}.currency`)}
                    onChange={e => inputExpensesCurrency(e, 'expenses_currency_' + i)}
                    defaultValue={field.currency}
                    disabled={isEditable}
                    id={`currency_${i}`}
                >
                    <option style={{"display": "none"}} value="">&nbsp;</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                </ExpensesSelect>
            </ExpensesDiv>

            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>AMOUNT</ExpensesLabel>
                }
                <ExpensesInput
                    // className={!field.amountincurrency && '_error_field'}
                    readOnly={isEditable}
                    key={field.id}
                    type="number"
                    defaultValue={field.amountincurrency}
                    {...register(`expenses.${i}.amountincurrency`)}
                    onChange={e => inputExpenses(e, 'expenses_amountincurrency_' + i)}
                    onFocus={handleFocus}
                    id={`amount_${i}`}
                />
            </ExpensesDiv>

            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>EXC. RATE</ExpensesLabel>
                }
                <ExpensesInput
                    readOnly={true}
                    key={field.id}
                    type="number"
                    defaultValue={field.exchangerate}
                    {...register(`expenses.${i}.exchangerate`)}
                    onChange={e => inputExpenses(e, 'expenses_exchangerate_' + i)}
                />
            </ExpensesDiv>
            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>AMOUNT</ExpensesLabel>
                }
                <ExpensesInput
                    readOnly={true}
                    key={field.id}
                    type="number"
                    defaultValue={field.amount|| 0}
                    {...register(`expenses.${i}.amount`)}
                    onChange={e => inputExpenses(e, 'expenses_amount_' + i)}
                />
            </ExpensesDiv>
            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>REF.CURRENCY</ExpensesLabel>
                }
                <ExpensesInput
                    readOnly={true}
                    type="text"
                    defaultValue={payroll.currency}
                    {...register(`expenses.${i}.referencecurrency`)}
                />
            </ExpensesDiv>
            <ExpensesDiv>
                {i === 0 &&
                <ExpensesLabel>COMMENT</ExpensesLabel>
                }
                <ExpensesTextArea
                    readOnly={isEditable}
                    key={field.id}
                    {...register(`expenses.${i}.comment`)}
                    onChange={e => inputExpensesTextArea(e, 'expenses_comment_' + i)}
                    placeholder="Type any comments here..."
                    defaultValue={field.comment}
                    rows="1"
                    cols="50"
                    onFocus={handleFocus}
                />
            </ExpensesDiv>
            <ExpensesDiv className={"add-file"} isEditable={isEditable}>
                <ExpensesLabel>
                    {(payroll.expenses[i] && payroll.expenses[i].file && (
                        <ExpensesDownloadFile
                            onClick={() => downloadExpensesFile(payroll.expenses[i].file)}
                        >
                            <ExpensesDownloadFileSvg>
                                <DownloadFile />
                            </ExpensesDownloadFileSvg>
                            {payroll.expenses[i].file && '1 FILE'}
                        </ExpensesDownloadFile>
                    )) || (
                        <ExpensesAddFile onClick={() => setShowDropZone(i)}>
                            <ExpensesAddFileSvg>
                                <AddFile />
                            </ExpensesAddFileSvg>
                            ADD FILE
                        </ExpensesAddFile>
                    )}
                    <ExpensesDeleteFile onClick={() => actionDeleteModal(true,i)}>
                        <ExpensesDeleteFileSvg>
                            <DeleteFile />
                        </ExpensesDeleteFileSvg>
                    </ExpensesDeleteFile>
                </ExpensesLabel>
            </ExpensesDiv>
            {payroll.expenses[i] && payroll.expenses[i].file ?
                <ExpensesDropZone>
                    <ExpensesDropZoneInner>
                        <ExpensesDeleteFileNameSvg onClick={() => actionDeleteFileModal(true,i)}>
                            <DeleteFile />
                        </ExpensesDeleteFileNameSvg>
                        <ExpensesFieldNameFile>{onlyLastPartOfFilename(payroll.expenses[i].file)}</ExpensesFieldNameFile>
                    </ExpensesDropZoneInner>
                </ExpensesDropZone>
                :
                <ExpensesDropZone>
                    <DropZone
                        index={i}
                        field={field}
                        fileName={fields[i].file}
                        action={AddFileExpenses}
                        showDropZone={showDropZone}
                    />
                </ExpensesDropZone>
            }
        </ExpensesContainer>
    )
}

export default ExpensesContainerRow

function onlyLastPartOfFilename(filename){
    if(filename.includes('iycpayroll.s3.amazonaws.com')){
        let newFilename = filename.split('-');
        newFilename.splice(0, 6)
        return newFilename.join('-')
    }
    return filename
}

const handleFocus = (event) => event.target.select();