export const formatDate = (date) => {
    let today = new Date(convertDateYYYYMMDD(date));
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
}

export const getMonthAndYearAndDates = (date) => {
    let today = date ? parseDate(date.split("/").reverse().join("-")) : new Date(convertDateYYYYMMDD(date));
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return [parseInt(dd, 10),parseInt(mm, 10),parseInt(yyyy, 10)]
}
 
export const convertDateMonthYear = (DATE) => {
    let dateObj = new Date(convertDateYYYYMMDD(DATE));
    let year = dateObj.getUTCFullYear();
    return monthNames[dateObj.getMonth()] + " " + year;
}

export const convertDate = (DATE) => {
    let date = new Date(convertDateYYYYMMDD(DATE));
    let normalizedDate = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
    let formattedDate = normalizedDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
    return formattedDate
}

function parseDate(input) {
    let parts = input.match(/(\d+)/g);
    return new Date(parts[0], parts[1]-1, parts[2]);
}

export const convertDateYYYYMMDD = (DATE) => {
    let today = DATE ? parseDate(DATE.split("/").reverse().join("-")) : new Date(DATE.split("/").reverse().join("-"));
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let yyyy = today.getFullYear();
    return  yyyy + '-' + mm + '-' + dd
}


export const dateFormatToYYYYMMDD = (date) => {
    if(date === ''){
        return
    }
    let today = new Date(date);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
}

export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const months = {
    '1' : 'January',
    '2' : 'February',
    '3' : 'March',
    '4' : 'April',
    '5' : 'May',
    '6' : 'June',
    '7' : 'July',
    '8' : 'August',
    '9' : 'September',
    '10' : 'October',
    '11' : 'November',
    '12' : 'December'
}


