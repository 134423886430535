import React from "react";
import {CloseExpenses, PlusBig} from "commons/svgs";
import ActionAddLineButton from "commons/ui/ActionAddLineButton";
import { useExpensesInputHandlers } from 'hooks/useExpensesInputHandlers';
import { useExpensesActions } from 'hooks/useExpensesActions'
import {AddCrewButton, SpanElement} from "components/headers/monthPayrollStyles";
import Modal from 'components/modal/Modal'
import ExpensesContainerRow from './ExpensesContainerRow'

import {
    CloseExpensesDiv,
    CloseExpensesSVG,
    ExpensesSection,
    ExpensesButtonCancel,
    ExpensesActionsButton,
    ExpensesSectionInnerInline,
    ExpensesSectionInner,
    ExpensesHeading,
    ExpensesHeadingContainer,
    ExpensesActionsSaveCancel
} from "../styles";

const Expenses = ({payroll,actions,payrolls,isEditable,fieldArrayMethods,control,index,watch}) => {

    const { fields } = fieldArrayMethods;
    
    const {
        openExpenses,
        setOpenExpenses,
        setShowDropZone,
        classAtr,
        setValue
    } = actions

    const {
        inputExpensesFileName,
    } = useExpensesInputHandlers(payrolls, setValue, payroll.id);

    const {
        actionUnsaved,
        actionDeleteModal,
        actionDeleteFileModal,
        actionCancelModal,
        actionSaveExpensesAndBack,
        actionSaveExpenses,
        actionUndo,
        deleteExpensesRowAndSaveExpenses,
        deleteExpensesFileAndSavePayroll,
        AddLineExpensesAction,
        AddFileExpenses,
        deleteModal,
        deleteFileModal,
        cancelModal,
        unSavedModal,
    } = useExpensesActions(payroll,payrolls,inputExpensesFileName,setOpenExpenses,setShowDropZone,fieldArrayMethods,openExpenses)
    
    return(
        <>
            <tr className={openExpenses ? classAtr + 'expenses_ no-border' : 'hide'}>
                <td>
                    {
                        openExpenses &&
                        <ExpensesSection className={openExpenses ? ' expenses-sections' : 'expenses-sections hide'}>
                            <ExpensesSectionInner>
                                <ExpensesSectionInnerInline>

                                    {fields.map((field, i) => {
                                        return (
                                            <React.Fragment key={field.id}>
                                                {i === 0 && (
                                                    <ExpensesHeadingContainer>

                                                        <CloseExpensesDiv onClick={ () => actionUnsaved(true)}>
                                                            <CloseExpensesSVG>
                                                                <CloseExpenses />
                                                            </CloseExpensesSVG>
                                                            <span>Close expenses</span>
                                                        </CloseExpensesDiv>
                                                        <ExpensesHeading className={'table-heading-sm'}>
                                                            Expenses for {payroll.full_name}
                                                        </ExpensesHeading>
                                                    </ExpensesHeadingContainer>
                                                )}
                                                <ExpensesContainerRow
                                                    i={i}
                                                    field={field}
                                                    fields={fields}
                                                    payrolls={payrolls}
                                                    payroll={payroll}
                                                    isEditable={isEditable}
                                                    actionDeleteModal={actionDeleteModal}
                                                    actionDeleteFileModal={actionDeleteFileModal}
                                                    actions={actions}
                                                    AddFileExpenses={AddFileExpenses}
                                                />
                                            </React.Fragment>
                                        );

                                    })}

                                    {
                                        deleteModal &&
                                        <Modal
                                            cancel={() => actionDeleteModal(false)}
                                            confirm={() => deleteExpensesRowAndSaveExpenses(payroll.id)}
                                            text='This action will delete the expense item and will save the expenses.'
                                        />
                                    }

                                    {
                                        deleteFileModal &&
                                        <Modal
                                            cancel={() => actionDeleteFileModal(false)}
                                            confirm={() => deleteExpensesFileAndSavePayroll(payroll.id)}
                                            text='This action will delete the file and will save the expenses.'
                                        />
                                    }

                                    {
                                        cancelModal &&
                                        <Modal
                                            cancel={() => actionCancelModal(false)}
                                            confirm={() => actionUndo()}
                                            text='This action will cancel all your unsaved changes.'
                                        />
                                    }

                                    {
                                        unSavedModal &&
                                        <Modal
                                            cancel={() => actionUndo(true)}
                                            confirm={() => actionSaveExpensesAndBack(payroll.expenses)}
                                            text='You want to save your unsaved changes?'
                                        />
                                    }

                                    {
                                        payroll.expenses && !isEditable &&
                                        <>
                                            <ExpensesActionsButton>
                                                <ActionAddLineButton action={AddLineExpensesAction}>
                                                <span>
                                                  <PlusBig />
                                                </span>
                                                    <div>ADD LINE</div>
                                                </ActionAddLineButton>
                                            </ExpensesActionsButton>

                                            <ExpensesActionsSaveCancel>
                                                <ExpensesActionsButton>
                                                    <AddCrewButton onClick={ () => actionSaveExpenses(payroll.expenses)}>
                                                        <SpanElement>SAVE EXPENSES</SpanElement>
                                                    </AddCrewButton>
                                                </ExpensesActionsButton>

                                                <ExpensesActionsButton>
                                                    <ExpensesButtonCancel onClick={() => actionCancelModal(true)}>
                                                        CANCEL
                                                    </ExpensesButtonCancel>
                                                </ExpensesActionsButton>
                                            </ExpensesActionsSaveCancel>

                                        </>
                                    }
                                </ExpensesSectionInnerInline>
                            </ExpensesSectionInner>
                        </ExpensesSection>
                    }
                </td>
            </tr>
        </>

    )
}

export default Expenses

