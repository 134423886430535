import React from "react";

import { CalendarSmall, InProgress, CheckPlus } from 'commons/svgs';
import {months,convertDate} from 'helpers/date'

import NavigationButton from "../navigation/NavigationButton";
import SelectStatus from 'components/select/SelectStatus'
import YachtSearch from 'components/search/YachtSearch'
import NoResults from 'components/noResults/NoResults'

import { TableHeader, ListTh, ListTd } from 'commons/ui/styles';
import {
    PayRollContainer,
    Calendar,
    Final,
    PayRollListTableCss,
} from 'components/payRollList/styles';

const AccountingPayrollView = ({accountingPayroll}) => {
    
    return (
        <>
            <PayRollListTableCss />
            <PayRollContainer width={'100%'} padding={'62px 0 0 0'}>
                {
                    accountingPayroll && accountingPayroll.length === 0  &&
                    <NoResults/>
                }
                {accountingPayroll &&  accountingPayroll.length > 0 && (
                    <>
                        <TableHeader>
                            Payroll {accountingPayroll[0].year || ''}
                            <YachtSearch/>
                        </TableHeader>
                        <table className={'table accounting-list'}>
                            <thead>
                            <tr className={'no-border'}>
                                <ListTh align={"left"} smallBold textTransform={'uppercase'}>Yacht</ListTh>
                                <ListTh align={"left"} smallBold textTransform={'uppercase'}>Month</ListTh>
                                <ListTh align={"left"} smallBold textTransform={'uppercase'}>Due Date</ListTh>
                                <ListTh align={"left"} smallBold textTransform={'uppercase'}><SelectStatus/></ListTh>
                                <ListTh align={"left"} smallBold textTransform={'uppercase'}>Finalised</ListTh>
                                <ListTh>&nbsp;</ListTh>
                            </tr>
                            </thead>
                            <tbody>
                            {accountingPayroll.map((payroll, i) => {
                                return (
                                    <React.Fragment key={payroll.id}>

                                        {/*<tr className={"no-border"}>*/}
                                        {/*    <td colSpan={'75%'}>*/}
                                        {/*        {i > 0 && payroll.year && (*/}
                                        {/*            <TableHeaderSmall className={'body-secondary-sm'}>Payroll {payroll.year}</TableHeaderSmall>*/}
                                        {/*        )}*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}

                                        {
                                            !payroll.hide &&
                                            <tr key={i}>
                                                <td>
                                                    <ListTd primary>
                                                        {payroll.yachtname && payroll.yachtname}
                                                    </ListTd>
                                                </td>
                                                <td>
                                                    <ListTd primary>
                                                        <Calendar>
                                                            <CalendarSmall />
                                                        </Calendar>

                                                        {payroll.month && months[payroll.month]}

                                                    </ListTd>
                                                </td>
                                                <td>
                                                    <ListTd primary>
                                                        {payroll.datedue && convertDate(payroll.datedue)}
                                                    </ListTd>
                                                </td>
                                                <td>
                                                    <ListTd
                                                        color={payroll.status === 'In Progress' ? '#FF7D51' : undefined}
                                                        primary
                                                    >
                                                        {payroll.status && payroll.status}
                                                    </ListTd>
                                                </td>
                                                <td>
                                                    <ListTd primary>
                                                        {resolveFinalized(payroll.status)}
                                                    </ListTd>
                                                </td>
                                                <td>
                                                    <ListTd primary className={'action-button pos-rel f-r'}>
                                                        {resolveNavigationButton(payroll)}
                                                    </ListTd>
                                                </td>
                                            </tr>
                                        }

                                    </React.Fragment>
                                );
                            })}
                            </tbody>
                        </table>
                    </>
                )}

            </PayRollContainer>
        </>
    );
};

const resolveNavigationButton = (payroll) => {
    if(payroll.status  === 'In Progress'){
        return (
            <NavigationButton
                buttonTheme={'secondary'}
                label={'VIEW'}
                query={
                    payroll.id
                }
                navigation={'month-payroll'}
                yachtid={payroll.yachtid}
            />
        )
    }

    if(payroll.status  === 'Submitted'){
        return (
            <NavigationButton
                buttonTheme={'primary'}
                label={'EDIT'}
                query={
                    payroll.id
                }
                navigation={'month-payroll'}
                yachtid={payroll.yachtid}
            />
        )
    }

    return (
        <NavigationButton
            buttonTheme={'secondary'}
            label={'VIEW'}
            query={
                payroll.id
            }
            navigation={'month-payroll'}
            yachtid={payroll.yachtid}
        />
    )

}

const resolveFinalized = (item) => {

    if (item === 'In Progress') {
        return (
            <span>
            <InProgress />
          </span>
        );
    }
    if (item === 'Submitted') {
        return (
            <span>
            <InProgress />
          </span>
        );
    }
    if (item === 'In Progress') {
        return (
            <span>
            <InProgress />
          </span>
        );
    }
    if (item === 'Approved') {
        return (
            <span>
            <InProgress />
          </span>
        );
    }

    return (
        <>
            Final
            <Final>
                <CheckPlus />
            </Final>
        </>
    );
}

export default AccountingPayrollView