import {useContext, useEffect, useState} from "react";
import {StateContext} from "context/DataContext";
import {toLocale} from "helpers/payroll";

const totals = {
    grosssalary: 0,
    totalexpenses: 0,
    deductions: 0,
    bonus: 0,
    gratuities: 0,
    flights: 0,
    unpaidleave: 0,
    total: 0,
    fees: 0,
    finaltotal: 0
}

const InitialState = {
    eur: totals,
    usd: totals
}

export const useMonthPayRollTotal = () => {
    const stateContext = useContext(StateContext)
    const [ monthTotal, setMonthTotal] = useState(InitialState)

    useEffect(()=>{
        let combineEUR = stateContext.singleMonthPayRoll && calculateAllTotalPrices(stateContext.singleMonthPayRoll, totals, 'EUR')
        let combineUSD = stateContext.singleMonthPayRoll && calculateAllTotalPrices(stateContext.singleMonthPayRoll, totals, 'USD')
        if(combineEUR || combineUSD) setMonthTotal({ eur: totalToLocale(combineEUR), usd: totalToLocale(combineUSD)})
    },[stateContext.singleMonthPayRoll])

    return {  monthTotal , show: stateContext.total };
};

const calculateAllTotalPrices = (arr,obj,currency) => {

    return arr.reduce((val,item)=>{
        if(item.currency === currency){
            val = {
                totalexpenses: parseFloat(val.totalexpenses) + parseFloat(item.totalexpenses),
                grosssalary: parseFloat(val.grosssalary) + parseFloat(item.grosssalary),
                deductions: parseFloat(val.deductions) + parseFloat(item.deductions),
                bonus: parseFloat(val.bonus) + parseFloat(item.bonus),
                gratuities: parseFloat(val.gratuities) + parseFloat(item.gratuities),
                flights: parseFloat(val.flights) + parseFloat(item.flights),
                unpaidleave: parseFloat(val.unpaidleave) + parseFloat(item.unpaidleave),
                total: parseFloat(val.total) + parseFloat(item.total),
                fees: parseFloat(val.fees) + parseFloat(item.fees),
                finaltotal: parseFloat(val.finaltotal) + parseFloat(item.finaltotal)
            }
        }
        return val
    },obj)
}

const totalToLocale = (combine) => {

    return {
        totalexpenses: toLocale(combine.totalexpenses),
        grosssalary: toLocale(combine.grosssalary),
        deductions: toLocale(-Math.abs(combine.deductions)),
        bonus: toLocale(combine.bonus),
        gratuities: toLocale(combine.gratuities),
        flights: toLocale(combine.flights),
        unpaidleave: toLocale(-Math.abs(combine.unpaidleave)),
        total: toLocale(combine.total),
        fees: toLocale(combine.fees),
        finaltotal: toLocale(combine.finaltotal)
    }
}

// const calculateExpenses = (expenses) => {
//     return expenses.reduce((val,item) => val += parseFloat(item.amount),0)
// }