import { useContext, useState } from 'react';
import {DispatchContext, StateContext} from 'context/DataContext';
import {useAuthDataContext} from "context/AuthContext";
import {deletePayrollById, savePayroll} from "services/services";

export const useHeaderMonthPayrollActions = (monthPayrollID) => {

    const dispatchDataContext = useContext(DispatchContext);
    const {unSavedChanges} = useContext(StateContext);
    const [modal,setModal] = useState(false)
    const [modalDelete,setModalDelete] = useState(false)
    const stateContext = useContext(StateContext);
    const {authData:{privileges,username}} = useAuthDataContext()
    
    const action = () => {
        if(unSavedChanges){
            return setModal(true)
        }
        dispatchDataContext({ type:'payrollHistory', payload:null })
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: null })
    };

    const ActionCancelForDelete = () => {
        setModalDelete(false)
    }
    const ActionOpenModalForDelete = () => {
        setModalDelete(true)
    }
    const ActionCancelForSave = () => {
        setModal(false)
    }
    const ActionContinueForSave = () => {
        setModal(false)
        dispatchDataContext({ type:'unSavedChanges', payload:false })
        dispatchDataContext({ type:'payrollHistory', payload:null })
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: null })
    }
    
    const Month = stateContext.payRollList && stateContext.payRollList.filter((item) => item.id === monthPayrollID)

    const deletePayroll = () => {
        dispatchDataContext({ type: 'loader', payload: true })
        deletePayrollById(Month[0].id,username).then(() => {
            dispatchDataContext({ type: 'singleMonthPayRoll', payload: null })
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
            return window.location.href = `/`;
        })
    }

    const savePayrollItems = () => {
        dispatchDataContext({ type: 'loader', payload: true })
        let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        savePayroll(final,username).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'submit', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
        })
    }

    const submitPayroll = () => {
        let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        dispatchDataContext({ type: 'loader', payload: true })
        savePayroll(final,username).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'submit', payload: true });
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
        })
    }

    const rejectPayroll = () => {
        let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        dispatchDataContext({ type: 'loader', payload: true })
        savePayroll(final,username).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'reject', payload: true });
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
            setTimeout(function (){
                dispatchDataContext({ type: 'submit', payload: true });
            },100)

        })
    }

    const approvePayroll = () => {
        let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        dispatchDataContext({ type: 'loader', payload: true })
        savePayroll(final,username).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'approve', payload: true });
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
            setTimeout(function (){
                dispatchDataContext({ type: 'submit', payload: true });
            },100)

        })
    }

    const processPayroll = () => {
        let final = createFinalPayrollForSave(stateContext.singleMonthPayRoll)
        dispatchDataContext({ type: 'loader', payload: true })
        savePayroll(final,username).then((data) => {
            if(data === '!Saved'){
                alert('Something Went Wrong')
            }
            dispatchDataContext({ type: 'process', payload: true });
            dispatchDataContext({ type: 'loader', payload: false })
            dispatchDataContext({ type: 'unSavedChanges', payload: false })
            setTimeout(function (){
                dispatchDataContext({ type: 'submit', payload: true });
            },100)

        })
    }

    const isMonthSubmitted = stateContext.payRollList && stateContext.payRollList.filter( month => month.id === monthPayrollID)
    const IsEditable = stateContext.payRollList ? editable(privileges,isMonthSubmitted[0]) : false

    return {
        privileges,
        modal,
        modalDelete,
        isMonthSubmitted,
        IsEditable,
        Month,
        actions: {
            action: action,
            ActionCancelForDelete: ActionCancelForDelete,
            ActionOpenModalForDelete: ActionOpenModalForDelete,
            ActionCancelForSave: ActionCancelForSave,
            ActionContinueForSave: ActionContinueForSave,
            deletePayroll: deletePayroll,
            savePayrollItems: savePayrollItems,
            submitPayroll: submitPayroll,
            rejectPayroll: rejectPayroll,
            approvePayroll: approvePayroll,
            processPayroll: processPayroll
        }
    }
}


const createFinalPayrollForSave = (data) => {
    return data.map(function(item){
        return (({ fees,bonus, comments,currency,dailyrate,daysoff,daysworked,deductions, enddate,finaltotal,flights,fullmonth,gratuities,grosssalary,id,idfcrew,idfpayroll,isprocessed,lastdatecrew,leavedailyaccrualrate,leavedaysaccrued,leavedaystaken,leavemonthendbalance,leavemonthstartbalance,month,monthlysalary,total,totalexpenses,type,unpaidleave,yacht,year }) =>
            ({ fees,bonus, comments,currency,dailyrate,daysoff,daysworked,deductions, enddate,finaltotal,flights,fullmonth,gratuities,grosssalary,id,idfcrew,idfpayroll,isprocessed,lastdatecrew,leavedailyaccrualrate,leavedaysaccrued,leavedaystaken,leavemonthendbalance,leavemonthstartbalance,month,monthlysalary,total,totalexpenses,type,unpaidleave,yacht,year }))(item)
    })
}

const editable = (privileges,data) => {

    if(!data){
        return true
    }

    if(privileges === 'Vessel' && data.issubmitted === true){
        return false
    }

    if(privileges === 'Accounting' && data.status === 'Approved'){
        return false
    }

    return true

}



