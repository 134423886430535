import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import { ActionDelete, DeleteButton, SmallDeleteArrowSvg } from 'commons/ui/styles';
import { BigDelete } from 'commons/svgs';

const ActionDeleteButton = ({ action }) => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <ActionDelete type="button" onClick={() => action()}>
        <DeleteButton>
          <SmallDeleteArrowSvg>
            <BigDelete />
          </SmallDeleteArrowSvg>
        </DeleteButton>
      </ActionDelete>
    </ThemeProvider>
  );
};

export default ActionDeleteButton;
