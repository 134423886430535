import React, { useContext } from 'react';
import { DispatchContext, StateContext } from 'context/DataContext';
import { ThemeContext } from 'context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import { Search } from 'commons/svgs';

const InputSearch = styled.input`
  background: ${props => props.theme.data.light.colors.Snow};
  border: 1px solid ${props => props.theme.data.light.colors.Carbon};
  width: 265px;
  padding: 11px 11px 11px 43px;
`;

const SearchArea = styled.div`
  position: relative;
  display: inline-block;
  float: left;
`;

const SearchSvg = styled.span`
  position: absolute;
  left: 15px;
  top: 12px;
  z-index: 1
`;

const PayRollMonthSearch = () => {
  const dispatchDataContext = useContext(DispatchContext);
  const stateContext = useContext(StateContext);
  const theme = useContext(ThemeContext);

  const setKeyword = async input => {
    const filtered = stateContext['singleMonthPayRoll'] && stateContext['singleMonthPayRoll'].map(payroll => {
      payroll.hide = !payroll.full_name.toLowerCase().includes(input.toLowerCase());
      return payroll;
    });
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: filtered });
  };

  return (
    <ThemeProvider theme={theme}>
      <SearchArea>
        <SearchSvg>
          <Search />
        </SearchSvg>
        <InputSearch key="searchKey" placeholder='Quick search' onChange={e => setKeyword(e.target.value)} />
      </SearchArea>
    </ThemeProvider>
  );
};

export default PayRollMonthSearch;
