import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import {ActionNextSubmit,
    ActionNextSubmitContainer,
    NextButtonSubmit,
    SmallNextSubmitArrowSvg
} from 'commons/ui/styles';
import {SubmitArrow} from 'commons/svgs';

const ActionSubmitButton = ({ label }) => {
    const theme = useContext(ThemeContext);

    return (
        <ThemeProvider theme={theme}>
            <ActionNextSubmitContainer>
                <ActionNextSubmit type="submit" label={label}/>
                <NextButtonSubmit>
                    <SmallNextSubmitArrowSvg>
                        <SubmitArrow />
                    </SmallNextSubmitArrowSvg>
                </NextButtonSubmit>
            </ActionNextSubmitContainer>

        </ThemeProvider>
    );
};

export default ActionSubmitButton;
