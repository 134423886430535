function strip(number) {
  return parseFloat(number).toPrecision(12);
}

export const daysAccrued = (payroll) => {
  if (payroll.leavedailyaccrualrate === "") {
    payroll.leavedailyaccrualrate = 0.0548;
  }
  payroll.leavedailyaccrualrate = payroll.leavedailyaccrualrate.toString();

  if (payroll.daysworked === 0) {
    // console.log(payroll);
    // console.log(payroll.leavedailyaccrualrate);
    if (payroll.leavedailyaccrualrate.includes(",")) {
      payroll.leavedailyaccrualrate.replace(",", ".");
      return parseFloat(payroll.leavedailyaccrualrate.replace(",", "."));
    }
  }

  if (payroll.leavedailyaccrualrate.includes(",")) {
    return (
      parseFloat(payroll.leavedailyaccrualrate.replace(",", ".")) *
      payroll.daysworked
    );
  }

  return parseFloat(payroll.leavedailyaccrualrate) * payroll.daysworked;
};

export const calculateEndBalance = (payroll) => {
  if (payroll.leavedailyaccrualrate === "") {
    payroll.leavedailyaccrualrate = 0.0548;
  }

  return (
    payroll.leavemonthstartbalance +
    daysAccrued(payroll) -
    payroll.leavedaystaken
  );
};

export const toLocale = (number) => {
  return number.toLocaleString("en-US", { minimumFractionDigits: 2 });
};

export const makeNegativeNumber = (number) => {
  return -Math.abs(parseFloat(number));
};

// export const makeNegativeNumber = number => {
//     return -Math.abs(Number(number));
// };
