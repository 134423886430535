import React, { useState } from 'react';
import {SelectArrow} from 'commons/svgs'
import styled from 'styled-components';

const selectValues = {
    EUR: 'EUR',
    USD: 'USD',
    GBP: 'GBP',
}

const SelectCurrency = ({action,defaultValue,fieldName}) => {

    const [show,setShow] = useState(false)
    const [activeOption,setActiveOption] = useState(defaultValue)

    const actionShow = (value) => {
        setShow(value)
    }

    const setCurrency = async (input,index) => {
        const target = {
            value: input
        }
        action({target},fieldName)
        actionShow(false)
        setActiveOption(index)
    };

    return (
        <>
            <span id={fieldName} onClick={ () => actionShow(true) }></span>
            <SelectName
                // className={!selectValues[activeOption] && '_error_field'}
                show={show}
                onClick={ () => actionShow(!show) }
            >
                <SelectValue>{selectValues[activeOption]}</SelectValue><SelectArrow/>
            </SelectName>
            {
                show &&
                <>
                    <SelectOptions>
                        {
                            Object.keys(selectValues).map(function(key, index) {
                                return(
                                    <SelectOption active={activeOption === index} key={index} onClick={() => setCurrency(selectValues[key],index)}>
                                        {selectValues[key]}
                                    </SelectOption>
                                )
                            })
                        }
                    </SelectOptions>
                    <SelectClear onClick={ () => actionShow(!show) }/>
                </>
            }
        </>
    )
}

const SelectName = styled.div`
    padding: 10px 5px 10px 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    -webkit-letter-spacing: 0.01em;
    -moz-letter-spacing: 0.01em;
    -ms-letter-spacing: 0.01em;
    letter-spacing: 0.01em;
    color: #090E36;
    border: 1px solid #C0CCDB;
    background: #FFFFFF;
    overflow: hidden;
    width: 60px;
    cursor: pointer;
    float: left;
    svg{
     margin-left: 5px;
     position: relative;
     top: -1px;
     transform: ${props => props.show ? 'rotate(0deg)' : ' rotate(180deg)'};
    }
    
    &._error_field{
       border: 1px solid red; 
    }
`;

const SelectValue = styled.span`
     padding: 0 3px;
     width: 33px;
     display: inline-block;
`

const SelectOptions = styled.div`
    position: absolute;
    width: 60px;
    padding: 5px;
    border: 1px solid ${props => props.theme.data.light.colors.Carbon};
    background: ${props => props.theme.data.light.colors.White};
    z-index: 2;
    margin-top: 39px;
`;

const SelectOption = styled.span`
    ${props => props.theme.data.light.typography['body-primary-l']};
    cursor: pointer;
    display: block;
    text-transform: none;
    padding: 10px 0 10px 10px;
    line-height: 14px;
    background: ${props => props.active ? props.theme.data.light.colors.DirtySnow : 'transparent'};
`;

const SelectClear = styled.div`
    position: fixed;
    z-index: 1;
    background: transparent;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
`;

export default SelectCurrency