import { LogoNavy } from 'commons/svgs';
import { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 27px;
`;

const LogoSection = styled.div`
  align-self: center;
  height: 30px;
`;

const MaxContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`;

const HeaderFull = styled.div`
  background: ${props => props.theme.data.light.colors.White};
  position: fixed;
  width: 100%;
`;

const LoginHeader = () => {
  const theme = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <HeaderFull>
        <MaxContainer>
          <HeaderContainer>
            <LogoSection>
              <LogoNavy />
            </LogoSection>
          </HeaderContainer>
        </MaxContainer>
      </HeaderFull>
    </ThemeProvider>
  );
};

export default LoginHeader;
