import {useState} from "react"

import Datepicker from 'components/datepicker/Datepicker'

import {
    NoteInputDatePicker,
    NoteInputSmall,
    NotesInputDiv,
    NotesLabel,
    NotesTextAreaDiv,
    TextArea,
    NoteInputLike,
    NotesInputDates,
    NoteCalendar, DivRelative
} from "../styles";
import {Calendar} from "commons/svgs";
import {dateFormatToYYYYMMDD,convertDateYYYYMMDD} from 'helpers/date'
import {daysAccrued,calculateEndBalance,toLocale} from 'helpers/payroll'
const handleFocus = (event) => event.target.select();

const Notes = ({payroll,actions,isEditable,index}) => {

     const {
        inputTextArea,
        inputDatesArea,
        inputChangeDaysOff,
        register,
        openNotes,
        classAtr,
    } = actions

    const [showCalendar, setShowCalendar] = useState(false)

    const openCalendar = (value) => {
        setShowCalendar(value)
    }


    return(

            openNotes === index &&
            <tr className={classAtr + ' payroll-notes'}>
                <td colSpan="75%">
                    <div>
                        <NotesInputDates>
                            <NotesLabel className={'table-secondary-sm'}>START DATE</NotesLabel>
                            <DivRelative>
                                <NoteCalendar disabled><Calendar/></NoteCalendar>
                                <NoteInputDatePicker
                                    readOnly={true}
                                    type="date"
                                    data-date-inline-picker="true"
                                    {...register('startdate')}
                                    onChange={e => inputDatesArea(e, 'startdate')}
                                    defaultValue={dateFormatToYYYYMMDD(convertDateYYYYMMDD(payroll.startdate))}
                                />
                            </DivRelative>
                        </NotesInputDates>
                        <NotesInputDates>
                            <NotesLabel className={'table-secondary-sm'}>END OF CONTRACT</NotesLabel>
                            <DivRelative>
                                <NoteCalendar>
                                    <Calendar/>
                                </NoteCalendar>
                                <div className={'datepicker-wrp'}>
                                    <div className={isEditable ? 'no-pointer' : undefined } onClick={() => openCalendar(!showCalendar)}>
                                        <div className="picker-date picker-date-big">
                                            <span>
                                                 {payroll.enddate || 'YYYY-MM-DD'}
                                            </span>
                                        </div>
                                        <div className="picker-date-input ">
                                            <NoteInputDatePicker
                                                readOnly={isEditable}
                                                type="date"
                                                data-date-inline-picker="true"
                                                {...register('enddate')}
                                                onChange={e => inputDatesArea(e, 'enddate')}
                                                defaultValue={payroll.enddate}
                                            />
                                        </div>
                                    </div>


                                    <div className="datepicker-calendar datepicker-calendar-big">
                                        {
                                            showCalendar && <div className="close-datepicker-wrap" onClick={() => openCalendar(false)}>

                                            </div>
                                        }
                                        <Datepicker
                                            date={dateFormatToYYYYMMDD(payroll.enddate)}
                                            fieldName={`enddate`}
                                            onChange={inputDatesArea}
                                            showCalendar={showCalendar}
                                            openCalendar={openCalendar}
                                        />
                                    </div>

                                </div>

                            </DivRelative>
                        </NotesInputDates>
                        <NotesInputDiv>
                            <NotesLabel className={'table-secondary-sm'}>DAY OFF</NotesLabel>
                            <NoteInputSmall
                                readOnly={isEditable}
                                type="number"
                                defaultValue={payroll.leavedaystaken}
                                {...register('leavedaystaken')}
                                onChange={e => inputChangeDaysOff(e, payroll.leavedaystaken)}
                                onFocus={handleFocus}
                            />
                        </NotesInputDiv>
                        <NotesInputDiv>
                            <NotesLabel className={'table-secondary-sm'}>START BALANCE</NotesLabel>
                            <NoteInputLike  className={"table-secondary-td"}>
                                {payroll.leavemonthstartbalance}
                            </NoteInputLike>
                        </NotesInputDiv>
                        <NotesInputDiv>
                            <NotesLabel className={'table-secondary-sm'}>DAYS ACCRUED</NotesLabel>
                            <NoteInputLike  className={"table-secondary-td"}>
                                {toLocale(daysAccrued(payroll))}
                            </NoteInputLike>
                        </NotesInputDiv>
                        <NotesInputDiv>
                            <NotesLabel className={'table-secondary-sm'}>END BALANCE</NotesLabel>
                            <NoteInputLike  className={"table-secondary-td"}>
                                {toLocale(calculateEndBalance(payroll))}
                            </NoteInputLike>
                        </NotesInputDiv>
                        <NotesTextAreaDiv>
                            <TextArea
                                readOnly={isEditable}
                                {...register('comments')}
                                placeholder="Type any comments here..."
                                onChange={e => inputTextArea(e)}
                                defaultValue={payroll.comments}
                                rows="4"
                                cols="50"
                            />
                        </NotesTextAreaDiv>
                    </div>
                </td>
            </tr>
    )
}

export default Notes