import { GlobalActionButtonElement } from './globalActionButtonStyles';
import React from "react";

const GlobalActionButton = ({ children, buttonTheme, action, label, ...props }) => {
    return(
        label ?
        <GlobalActionButtonElement
            type="button"
            buttonTheme={buttonTheme}
            onClick={() => {
                action && action();
            }}
        >
            {label}
        </GlobalActionButtonElement>
        :
        <GlobalActionButtonElement
            type="button"
            buttonTheme={buttonTheme}
            onClick={() => {
                action && action();
            }}
        >
            {children}
        </GlobalActionButtonElement>
       
    )
}

export default GlobalActionButton