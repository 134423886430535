import styled, { createGlobalStyle } from 'styled-components';

export const PayRollMonthContainer = styled.div`
  position: fixed;
  width:100%;
  left: 0;
  bottom: 0;
  background: ${props => props.theme.data.light.colors.White};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 13px 0 12px 0;
  display: block;
`;

export const PayRollMonthContainerInner = styled.div`
  max-width: ${props => props.Maxwidth || '1440px'};
  margin: 0 auto;
`;

export const TableTH = styled.th`
   ${props => props.theme.data.light.typography['table-sh']};
   color: ${props => props.theme.data.light.colors.Smoke};
   text-align: center !important;
   text-transform: uppercase;
   padding-bottom: 11px;
`;

export const TableTD = styled.td`
   ${props => props.theme.data.light.typography['table-sd']};
   color: ${props => props.theme.data.light.colors.NightSky};
   text-align: center !important;
   text-transform: uppercase;
   padding: 5px 0;
   
   > span{
      color: ${props => props.theme.data.light.colors.FireRose};
   }
   
   em{
     ${props => props.theme.data.light.typography['body/primary-sm']};
     font-weight: 600;
   }
`;

export const MonthPayRollTableCss = createGlobalStyle`

    .table.total{
      max-width: 949px;
      float: right;
      table-layout: fixed;
    }
    
     .table.total.full__{
      max-width: 1149px;
      float: right;
    }
    
    .table.total tr{
      border-bottom: 0;
    }

    .table.total td:nth-child(1){
       width: 100px;
    }
    
    .table.total th:nth-child(1){
       width: 100px;
    }
    
    .table.total td:nth-child(2){
       width: 122px;
    }
    
    .table.total th:nth-child(2){
       width: 122px;
    }
    
    .table.total td:nth-child(3){
       width: 0;
    }
    
    .table.total th:nth-child(3){
       width: 0;
    }
    
    .table.total th:nth-child(4){
       width: 101px;
    }
    
    .table.total td:nth-child(4){
       width: 101px;
    }
    

    .table.total td:nth-child(5){
       width: 100px;
    }
    
    .table.total th:nth-child(5){
       width: 100px;
    }
    
    .table.total td:nth-child(6){
       width: 100px;
    }
    
    .table.total th:nth-child(6){
       width: 100px;
    }
    
    .table.total td:nth-child(7){
       width: 100px;
    }
    
    .table.total th:nth-child(7){
       width: 100px;
    }
    
    .table.total td:nth-child(8){
        width: 100px;
    }
    
    .table.total th:nth-child(8){
       width: 100px;
    }
    
    .table.total td:nth-child(9){
       width: 94px;
    }
    
    .table.total th:nth-child(9){
       width: 94px;
    }
    
    .table.total td:nth-child(10){
       width: 135px;
    }
    
    .table.total th:nth-child(10){
       width: 135px;
    }
    
   .table.total td:nth-child(11){
       width: 94px;
    }
    
    .table.total th:nth-child(11){
      width: 94px;
    }
    
    .table.total td:nth-child(12){
      width: 135px;
    }
    
    .table.total th:nth-child(12){
      width: 135px;
    }
`;
