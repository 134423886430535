import React, { useContext } from 'react';

import { DispatchContext } from 'context/DataContext';
import {getExchangeRate} from "../services/services";
import {formatDate} from "../helpers/date";

export const useExpensesInputHandlers = (singleMonthPayRoll, setValue, id) => {
    const dispatchDataContext = useContext(DispatchContext);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    const inputExpenses = ({ target }, name) => {
        const { value } = target;
        console.log(value)
        const newPayRoll = updateExpensesPayRoll(singleMonthPayRoll, id, name, value, setValue);

        dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
    };

    const inputExpensesCurrency = ({ target }, name) => {
        
        const { value } = target;
        
        const index = name.split('_')[2];
        const field = name.split('_')[1];
        
        const newPayRoll = updateExpensesPayRollCurrency(singleMonthPayRoll, id, name, value, setValue, dispatchDataContext,forceUpdate);
        
        if(!newPayRoll){
           updateExpensesWithNoRates(singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue)
           return
        }

        updateExpensesContext(newPayRoll,singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue)
        
    };
    
    const inputExpensesFileName = ({ target },name) => {
        const { file } = target;
        const newPayRoll = updateExpensesPayRollFileName(singleMonthPayRoll, id,name,file);
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
        forceUpdate();
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
    };

    const inputExpensesDates = ({ target }, name) => {
        const { value } = target;
        
        const index = name.split('_')[2];
        const field = name.split('_')[1];

        const newPayRoll  = updateExpensesDatesPayRoll(singleMonthPayRoll, id, name, value, setValue);
        
        if(!newPayRoll){
            updateExpensesWithNoRates(singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue)
            return
        }
        
        updateExpensesContext(newPayRoll,singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue)
        
    };

    const inputExpensesTextArea = ({ target }, name) => {
        const { value } = target;
        const newPayRoll = updateExpensesTextAreaPayRoll(singleMonthPayRoll, id, name, value);
        dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRoll });
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
    };
    
    return {
        inputExpenses,
        inputExpensesDates,
        inputExpensesTextArea,
        inputExpensesFileName,
        inputExpensesCurrency,
        forceUpdate,
    };
};

const calculateTotal = (payroll) => {
    return payroll.bonus + payroll.gratuities + payroll.totalexpenses + payroll.grosssalary + payroll.flights + payroll.unpaidleave + -Math.abs(parseFloat(payroll.deductions))
};

const updateExpensesDatesPayRoll = (array, id, name, value,setValue) => {
    let index = name.split('_')[2];
    
    let getPayrollId = array.filter(payroll => payroll.id === id)

    console.log(getPayrollId[0])
    console.log(getPayrollId[0].expenses.currency)
    if(value  && getPayrollId[0].expenses[index]['currency']){
        let fDate = formatDate(value)
        return getExchangeRate(fDate,getPayrollId[0].expenses[index]['currency'],getPayrollId[0].currency)
    }
    return
};

const updateExpensesPayRollFileName = (array, id, name, fileNameValue) => {
    let index = name.split('_')[2];

    return array.map(payroll => {
        if (payroll.id === id) {
            payroll.expenses[index]['file'] = fileNameValue;
        }
        return payroll;
    });
};

const updateExpensesPayRoll = (array, id, name, value,setValue) => {
    let index = name.split('_')[2];
    let field = name.split('_')[1];

    console.log(value)
    if( value === '') value = 0
    return array.map(payroll => {
        if (payroll.id === id) {
            payroll.expenses[index][field] = parseFloat(value);
            payroll.expenses[index]['amount'] =  parseFloat(payroll.expenses[index]['exchangerate'] || 0) * parseFloat(value || 0)
            payroll['totalexpenses'] = parseFloat(updateTotalExpenses(payroll));
            payroll['total'] = calculateTotal(payroll);
            delay(function(){
                setValue('expenses', removeKey(payroll.expenses));
            }, 1500 );
        }
        return payroll;
    });
};

const delay = (function(){
    let timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

const updateExpensesPayRollCurrency =  (array, id, name, value, setValue, dispatch,forceUpdate) => {
    let index = name.split('_')[2];
    
    let getPayrollId = array.filter(payroll => payroll.id === id)

    if(getPayrollId[0].expenses[index]['date'] && value){
        let fDate = formatDate(getPayrollId[0].expenses[index]['date'])
        return getExchangeRate(fDate,value,getPayrollId[0].currency)
    }

};

const removeKey = (arr) => {
    return arr.map( item=> {
        delete item.id
        return item
    })
}

const updateTotalExpenses = (payroll) =>{
    return payroll.expenses.reduce((amm,item)=> amm += item.amount,0)
}

const updateExpensesTextAreaPayRoll = (array, id, name, value) => {
    let index = name.split('_')[2];
    let field = name.split('_')[1];
    return array.map(payroll => {
        if (payroll.id === id) {
            payroll.expenses[index][field] = value;
        }
        return payroll;
    });
};



const updateExpensesContext = (newPayRoll,singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue) => {

    newPayRoll.then(data => {
        let newPayRolls = singleMonthPayRoll.map(payroll => {
            if (payroll.id === id) {
                let rate = data.rate
                if(!rate) rate = 0
                payroll.expenses[index][field] = value;
                payroll.expenses[index]['exchangerate'] = parseFloat(rate)
                payroll.expenses[index]['amount'] =  parseFloat(rate || 0) * parseFloat(payroll.expenses[index]['amountincurrency'] || 0)
                payroll['totalexpenses'] = parseFloat(updateTotalExpenses(payroll));
                payroll['total'] = calculateTotal(payroll);
                setValue('expenses', removeKey(payroll.expenses));
            }
            return payroll;
        });

        dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRolls });
        dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
    })
}

const updateExpensesWithNoRates = (singleMonthPayRoll,dispatchDataContext,index,field,value,id,setValue) => {
    let newPayRolls = singleMonthPayRoll.map(payroll => {
        if (payroll.id === id) {
            payroll.expenses[index][field] = value;
            setValue('expenses', removeKey(payroll.expenses));
        }
        return payroll;
    });
    dispatchDataContext({ type: 'singleMonthPayRoll', payload: newPayRolls });
    dispatchDataContext({ type: 'unSavedChangesExpenses', payload: true });
}


