import React, {useContext, useEffect,useCallback,useState} from "react";
import {getPayrollHistory} from 'services/services'
import {DispatchContext,StateContext} from "context/DataContext";
import {useAuthDataContext} from 'context/AuthContext'
import GlobalActionButton from 'commons/ui/GlobalActionButton'
import {Book,BigClose,AvatarHistory} from 'commons/svgs'
import {HistoryModal,HistoryModalInner,HistoryModalBG,HistoryModalTop,HistoryModalClose,HistorySection,
    HistorySectionRow,
    HistorySectionDiv,HistorySectionComment,HistorySectionAvatar,HistorySectionStatus,CommentSection,
    HistorySectionDivName,HistorySectionDivRank,HistorySectionTime,HistorySectionStatusIn,HistorySectionDivAvatar
} from './styles'

const PayRollHistory = ({monthPayrollID}) => {
    const dispatchDataContext = useContext(DispatchContext)
    const stateContext = useContext(StateContext)
    const fetchData = useCallback(getPayrollHistory,[dispatchDataContext])
    const {authData:{username}} = useAuthDataContext()

    const [open,setOpen] = useState(false)

    const actionOpenModal = () => {
        setOpen(!open)
    }

    useEffect(() => {
        fetchData(monthPayrollID,username).then((data)=> {
            dispatchDataContext({type:'payrollHistory',payload: data ? data.payrollhistory : null})}
        )
    }, [dispatchDataContext,fetchData,username,monthPayrollID]);


    return(
        <>
            {
                stateContext.payrollHistory && stateContext.payrollHistory.length > 0 &&
                <GlobalActionButton buttonTheme={'Snow'} action={actionOpenModal}>
                    <span><Book/></span>
                    <span>HISTORY</span>
                </GlobalActionButton>
            }

            {
                open &&
                <HistoryModal>
                    <HistoryModalBG />
                    <HistoryModalInner>
                        <HistoryModalTop>
                            History
                            <HistoryModalClose onClick={actionOpenModal}>
                                <BigClose/>
                            </HistoryModalClose>
                        </HistoryModalTop>
                        {
                            stateContext.payrollHistory && stateContext.payrollHistory.map((item,i)=>{
                                return(
                                    <HistorySection key={i}>
                                        <HistorySectionRow>
                                            <HistorySectionStatus>
                                                <HistorySectionTime>
                                                    {item.timestamp}
                                                </HistorySectionTime>
                                                <HistorySectionStatusIn orange={item.status === 'In Progress'}>
                                                    {item.status}
                                                </HistorySectionStatusIn>
                                            </HistorySectionStatus>
                                            <HistorySectionAvatar>
                                                <HistorySectionDivAvatar>
                                                    <AvatarHistory/>
                                                </HistorySectionDivAvatar>
                                            </HistorySectionAvatar>
                                            <HistorySectionComment>
                                                <HistorySectionDiv>
                                                    <HistorySectionDivName>
                                                        {item.fullname}
                                                    </HistorySectionDivName>
                                                    <HistorySectionDivRank>
                                                        {item.rank}
                                                    </HistorySectionDivRank>
                                                </HistorySectionDiv>
                                                <HistorySectionDiv>
                                                    <CommentSection>
                                                        {item.comment}
                                                    </CommentSection>
                                                </HistorySectionDiv>
                                            </HistorySectionComment>
                                        </HistorySectionRow>
                                    </HistorySection>
                                )
                            })
                        }
                    </HistoryModalInner>
                </HistoryModal>
            }
        </>
   
    )
}

export default PayRollHistory