import styled,{css} from 'styled-components';

export const HeaderTopSection = styled.div`
   display: flex;
`;

export const HeaderTopSectionInner = styled.div`
   flex: 50%;
`;

export const HeaderTopSectionInnerHistory = styled.div`
   flex: 50%;
   float: right;
   margin-top: 30px;
   text-align: right;
`;

export const HeaderBottomSection = styled.div`
  display: block;
  float: left;
  width: 100%;
  margin-top: 36px;
  padding: 0 0 0 22px;
`;

export const BackSvg = styled.span`
  position: relative;
  top: 2px;
  cursor: pointer;
  padding-right: 10px;
`;

export const AddCrewSvg = styled.span`
  position: absolute;
  top: 12px;
  cursor: pointer;
  padding-right: 10px;
  left: 14px;
`;

export const AddCrewButton = styled.button`
  position: relative;
  cursor: pointer;
  display: inline-block;
  float: left;
  background: #e5e9f3;
  border: 0;
  padding: 11px 23px 10px 23px;
  background: ${props => props.theme.data.light.colors.BlueColor};
  color: ${props => props.theme.data.light.colors.White};
  border: 0;
  margin-left: 30px;
  margin-right: 10px;
  
  &:hover{
     opacity: 0.8;
  }
`;

export const PayRollSearchContainer = styled.div`
  display: inline-block;
  float: left;
  margin-left: 28px;
`;

export const TopSection = styled.div`
  padding-top: 23px;
  padding-left: 17px;
`;

export const DivElement = styled.div`
  padding-top: 6px;
  padding-left: 53px;
  text-transform: uppercase;
   ${props =>
    props.color === 'inprogress' &&
    css`
       color: ${props => props.theme.data.light.colors.Orange};
    `};
   ${props =>
    props.color === 'submitted' &&
    css`
       color: ${props => props.theme.data.light.colors.Spurle};
    `};
`;

export const SpanElement = styled.span`
  cursor: pointer;
  ${props => props.theme.data.light.typography['table-sd']}

 
`;

export const NextDeleteSection = styled.div`
  display: inline-block;
  float: right;
`;

export const NextDeleteDiv = styled.div`
  display: inline-block;
  float: right;
  margin-right: 10px;
`;


export const ModalContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(255,255,255,0.5);
    z-index: 100;
`

export const ModalContainerInner = styled.div`
    display: block;
    width: 321px;
    margin: 0 auto;
    background: #fff;
    padding: 0 0 20px 0;
    -webkit-box-shadow: 7px 7px 5px -5px rgb(0 0 0 / 75%);
    -moz-box-shadow: 7px 7px 5px -5px rgba(0,0,0,0.75);
    box-shadow: 7px 7px 5px -5px rgb(0 0 0 / 75%);
`

export const ModalContainerText = styled.div`
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
`
export const ModalContainerContinue = styled.div`
    display: inline-block;
    width: 100px;
    border: 1px solid #1458EC;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background: #1458EC;
    color: #fff;
    font-size: 13px;
`

export const ModalContainerCancel = styled.div`
    display: inline-block;
    width: 100px;
    border: 1px solid;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background: transparent;
    color: #000;
    margin-left: 23px;
    margin-right: 25px;
    font-size: 13px;
`
