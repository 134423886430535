import React from "react";

import ToolTipWrapper from 'components/tooltip/Tooltip'

import {
    ExprensesAmmountDiv,
    Input,
    InputSmall,
    ListTd,
    NoteSvg,
    OpenNotes,
    PlusSmallSvg,
    PlusSvg,
    Price
} from "../styles";
import {ArrowDown, Note, PlusBig, PlusSmall} from "commons/svgs";
import getSymbolFromCurrency from "currency-symbol-map";
import {makeNegativeNumber,toLocale} from "helpers/payroll";

const handleFocus = (event) => event.target.select();

const PayRollRow = ({payroll,actions,isEditable,index,privileges}) => {

    const {
        classAtr,
        openNotes,
        notesActionOpen,
        register,
        inputChange,
        AddExpenses,
        inputChangeDates
    } = actions

    const currency = getSymbolFromCurrency(payroll.currency)

    return(
        <tr className={classAtr}>
            <td className={"no-input-field"}>

                <OpenNotes className={openNotes === index && 'is-open-notes'} onClick={() => notesActionOpen(index)}>
                    <ArrowDown />
                </OpenNotes>

                <ListTd className={'table-secondary-td'}>{payroll.full_name && payroll.full_name}</ListTd>
            </td>
            <td className={"no-input-field"}>
                <ListTd className={'table-secondary-td m-w'}>
                    <ToolTipWrapper
                         text={payroll.comments}
                         renderProp={
                             <NoteSvg>
                                 <Note color={(payroll.comments && '#FF3434') || '#090E36'} />
                             </NoteSvg>
                         }
                    />
                </ListTd>
            </td>
            <td className={"no-input-field"}>
                <ListTd className={'table-secondary-td text-center'}>
                    <Price>{currency}</Price>
                    <Price>{toLocale(payroll.monthlysalary)}</Price>
                </ListTd>
            </td>
            <td className={"no-input-field"}>
                <ListTd className={'table-secondary-td text-center'}>
                    <Price>{currency}</Price>
                    <Price>{toLocale(payroll.dailyrate)}</Price>
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>

                    <InputSmall
                        readOnly={isEditable}
                        type="number"
                        defaultValue={payroll.daysworked}
                        {...register('daysworked')}
                        min={0}
                        max={payroll.maxdaysmonth}
                        onChange={e => inputChangeDates(e,payroll.maxdaysmonth)}
                        onFocus={handleFocus}
                    />

                </ListTd>
            </td>
            <td className={"no-input-field"}>
                <ListTd className={'table-secondary-td text-center'}>
                    <Price>{currency}</Price>
                    <Price>{toLocale(payroll.grosssalary)}</Price>
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>
                    {(payroll.totalexpenses === 0 && (
                        <PlusSvg onClick={() => AddExpenses()}>
                            <PlusBig />
                        </PlusSvg>
                    )) || (
                        <ExprensesAmmountDiv onClick={() => AddExpenses()}>
                            {currency} {toLocale(payroll.totalexpenses)}
                            <PlusSmallSvg>
                                <PlusSmall />
                            </PlusSmallSvg>
                        </ExprensesAmmountDiv>
                    )}
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>
                    <Input
                        readOnly={isEditable}
                        type="text"
                        defaultValue={makeNegativeNumber(payroll.deductions)}
                        {...register('deductions')}
                        onChange={e => inputChange(e, 'deductions', 'negative')}
                        onFocus={handleFocus}
                        id={`deductions_${index}`}
                    />
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>
                    <Input
                        readOnly={isEditable}
                        type="text"
                        defaultValue={payroll.bonus}
                        {...register('bonus')}
                        onChange={e => inputChange(e, 'bonus')}
                        onFocus={handleFocus}
                    />
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>
                    <Input
                        readOnly={isEditable}
                        type="text"
                        defaultValue={payroll.gratuities}
                        {...register('gratuities')}
                        onChange={e => inputChange(e, 'gratuities')}
                        onFocus={handleFocus}
                    />
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center'}>
                    <Input
                        readOnly={isEditable}
                        type="text"
                        defaultValue={payroll.flights}
                        {...register('flights')}
                        onChange={e => inputChange(e, 'flights')}
                        onFocus={handleFocus}
                    />
                </ListTd>
            </td>
            <td>
                <ListTd className={'table-secondary-td text-center smoke-border-right'}>
                    <Input
                        readOnly={isEditable}
                        type="text"
                        defaultValue={makeNegativeNumber(payroll.unpaidleave)}
                        {...register('unpaidleave')}
                        onChange={e => inputChange(e, 'unpaidleave', 'negative')}
                        onFocus={handleFocus}
                    />
                </ListTd>
            </td>
            <td className={ privileges !== 'Vessel' ? 'no-input-field' : 'no-border-right no-input-field'}>
                <ListTd className={'table-secondary-td text-right sm-pad-right'}>
                    <Price>{currency}</Price>
                    <Price className={'table-total'}>{toLocale(payroll.total)}</Price>
                </ListTd>
            </td>
            {
                privileges !== 'Vessel' &&
                    <>
                        <td>
                            <ListTd className={'table-secondary-td text-center'}>
                                <Input
                                    readOnly={isEditable}
                                    type="text"
                                    defaultValue={payroll.fees}
                                    {...register('fees')}
                                    onChange={e => inputChange(e, 'fees')}
                                    onFocus={handleFocus}
                                />
                            </ListTd>
                        </td>
                        <td className={'no-border-right no-input-field'}>
                            <ListTd className={'table-secondary-td text-right sm-pad-right'}>
                                <Price>{currency}</Price>
                                <Price className={'table-total'}>
                                    {toLocale(payroll.finaltotal)}
                                </Price>
                            </ListTd>
                        </td>
                    </>
            }

        </tr>
    )
}

export default PayRollRow

