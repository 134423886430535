import styled from 'styled-components';
import {NoResultsIcon} from 'commons/svgs'

const NoResultsContainer = styled.div`
   background: ${props => props.theme.data.light.colors.Snow};
   width: 402px;
   padding: 40px 10px 47px 10px;
   margin: 0 auto;
   text-align: center;
`;

const NoResultsIconSearch = styled.div`
  
`;

const NoResultsText = styled.h2`
   ${props => props.theme.data.light.typography['body-primary-sm']};
   color: ${props => props.theme.data.light.colors.NightSky};
   padding: 20px 0 4px 0;
`;

const NoResultsTextSmall = styled.p`
    ${props => props.theme.data.light.typography['body-secondary-l']};
    color: ${props => props.theme.data.light.colors.Smoke};
`;

const NoResults = () => {

   return(
       <>
           <NoResultsContainer>
               <NoResultsIconSearch>
                   <NoResultsIcon/>
               </NoResultsIconSearch>
               <NoResultsText>
                   Sorry no results found!
               </NoResultsText>
               <NoResultsTextSmall>
                   Lorem ipsum dummy data
               </NoResultsTextSmall>
           </NoResultsContainer>
       </>
   )
}

export default NoResults