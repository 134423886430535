import {
  ModalClose,
  ModalContainer,
  ModalContainerBG,
  ModalContainerInner,
  ModalTextFinalStep,
  ModalButtonsArea,
  ModalText,
} from "./styles";
import { BigClose } from "commons/svgs";
import ActionConfirm from "commons/ui/ActionConfirm";
import ActionCancel from "commons/ui/ActionCancelButton";
import NavigationButton from "components/navigation/NavigationButton";

const Modal = ({ cancel, confirm, text, navigation }) => {
  console.log("the modal");
  return (
    <ModalContainer>
      <ModalContainerBG />
      <ModalContainerInner>
        <ModalClose onClick={cancel}>
          <BigClose />
        </ModalClose>
        <ModalTextFinalStep>
          <ModalText>{text}</ModalText>
          <ModalButtonsArea>
            {navigation ? (
              <NavigationButton action={confirm} navigation={navigation}>
                <ActionConfirm />
              </NavigationButton>
            ) : (
              <ActionConfirm action={confirm} />
            )}
            <ActionCancel action={cancel} />
          </ModalButtonsArea>
        </ModalTextFinalStep>
      </ModalContainerInner>
    </ModalContainer>
  );
};

export default Modal;
