import styled, { css } from 'styled-components'

export const GlobalActionButtonElement = styled.button`
  ${props => props.buttonTheme === 'primary' && props.theme.data.light.typography['table-sd']}
  ${props => props.buttonTheme === 'secondary' && props.theme.data.light.typography['table-sd']}
  ${props => props.buttonTheme === 'Snow' && props.theme.data.light.typography['body-small-r']}
  ${props =>
    props.buttonTheme === 'Snow' &&
    css`
       background: ${props => props.theme.data.light.colors.Snow};
       color:  ${props => props.theme.data.light.colors.NightSky};
       border: 1px solid ${props => props.theme.data.light.colors.NightSky};
       padding: 10px 30px 9px 30px;
       cursor: pointer;
       min-width: 136px;
       margin-right: 10px;
       position: relative;
       > span:nth-child(1){
          position: absolute;
          left: 11px;
       }
       > span:nth-child(2){
         margin-left: 20px;
       }
       &:hover{
         opacity: 0.8;
       }
    `};
  ${props =>
    props.buttonTheme === 'primary' &&
    css`
       background: ${props => props.theme.data.light.colors.NightSky};
       color:  ${props => props.theme.data.light.colors.White};
       border: 1px solid ${props => props.theme.data.light.colors.NightSky};
       padding: 10px 30px 9px 30px;
       cursor: pointer;
       min-width: 147px;
       margin-right: 10px;
       &:hover{
         opacity: 0.8;
       }
    `};
  ${props =>
    props.buttonTheme === 'secondary' &&
    css`
       background: ${props => props.theme.data.light.colors.DirtySnow};
       color:  ${props => props.theme.data.light.colors.NightSky};
       border: 1px solid ${props => props.theme.data.light.colors.DirtySnow};
       padding: 10px 30px 9px 30px;
       min-width: 147px;
       cursor: pointer;
       margin-right: 10px;
       &:hover{
         opacity: 0.8;
       }
    `};
`
