import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const Datepicker = ({date,fieldName,onChange,showCalendar,openCalendar}) => {
    
    const [pickerState,setPickerState] = useState({
        selectedDay: new Date(date),
    })
    
    const handleDayClick = (day) => {
        
        const target = {
            value: convertDateYYYYMMDD(formatDate(day))
        }
        
        onChange({target}, fieldName)
        openCalendar(false)
        setPickerState({ selectedDay: new Date(formatDate(day)) });
    }

    return(
        showCalendar ?
        <DayPicker
            onDayClick={handleDayClick}
            selectedDays={pickerState.selectedDay}
        /> : null
    )
    
}

export default Datepicker

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('/');
}

const convertDateYYYYMMDD = (DATE) => {
    if(DATE){
        return DATE.split("/").join("-")
    }
}
