import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';
import {ActionCancelButton} from 'commons/ui/styles';

const ActionCancel  = ({ action }) => {
    const theme = useContext(ThemeContext);

    return (
        <ThemeProvider theme={theme}>
            <ActionCancelButton type="button" onClick={() => action()}>
                CANCEL
            </ActionCancelButton>
        </ThemeProvider>
    );
};

export default ActionCancel;
