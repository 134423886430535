import React, { useContext, useState } from 'react';
import { DispatchContext, StateContext } from 'context/DataContext';
import {SelectArrow} from 'commons/svgs'
import styled from 'styled-components';
import {convertDateYYYYMMDD} from 'helpers/date'

const selectValues = {
    inprogres: 'In Progress',
    submitted: 'Submitted',
    approved: 'Approved',
    processed: 'Processed'
}

const SelectStatus = () => {

    const dispatchDataContext = useContext(DispatchContext);
    const stateContext = useContext(StateContext);
    const [show,setShow] = useState(false)
    const [activeOption,setActiveOption] = useState(0)

    const actionShow = (value) => {
        setShow(value)
    }

    const setStatus = async (input,index) => {
        const filtered = stateContext['payRollList'] && sortingPayroll(stateContext['payRollList'],input)
        dispatchDataContext({ type: 'payRollList', payload: filtered });
        actionShow(false)
        setActiveOption(index)
    };

    return (
        <>
          <SelectName show={show} onClick={ () => actionShow(!show) }>STATUS <SelectArrow/></SelectName>
            {
                show &&
                    <>
                        <SelectOptions>
                            {
                                Object.keys(selectValues).map(function(key, index) {
                                    return(
                                        <SelectOption active={activeOption === index} key={index} onClick={() => setStatus(selectValues[key],index)}>
                                            {selectValues[key]}
                                        </SelectOption>
                                    )
                                })
                            }
                        </SelectOptions>
                        <SelectClear onClick={ () => actionShow(!show) }/>
                    </>
            }
        </>
    )
}

function sortingPayroll (payroll,status) {

    let sortValues = {
        approved: [],
        inprogress: [],
        final: [],
        submitted: []
    }

    let SortPayroll = payroll.reduce((finalObj,item)=>{
        if(item.status === 'Approved'){
            finalObj.approved.push(item)
            return finalObj
        }
        if(item.status === 'Submitted'){
            finalObj.submitted.push(item)
            return finalObj
        }
        if(item.status === 'In Progress'){
            finalObj.inprogress.push(item)
            return finalObj
        }

        if(item.status === 'Processed'){
            finalObj.final.push(item)
            return finalObj
        }

        return  finalObj
    },sortValues)

    return orderItems(status,SortPayroll)
}

function orderItems(status,SortPayroll){
    if(status === 'Approved'){
        return  [
            ...CompareByDate(SortPayroll.approved),
            ...CompareByDate(SortPayroll.inprogress),
            ...CompareByDate(SortPayroll.submitted),
            ...CompareByDate(SortPayroll.final)
        ]
    }

    if(status === 'In Progress'){
        return  [
            ...CompareByDate(SortPayroll.inprogress),
            ...CompareByDate(SortPayroll.submitted),
            ...CompareByDate(SortPayroll.approved),
            ...CompareByDate(SortPayroll.final)
        ]
    }

    if(status === 'Submitted'){
        return  [
            ...CompareByDate(SortPayroll.submitted),
            ...CompareByDate(SortPayroll.inprogress),
            ...CompareByDate(SortPayroll.approved),
            ...CompareByDate(SortPayroll.final)
        ]
    }

    if(status === 'Processed'){
        return  [
            ...CompareByDate(SortPayroll.final),
            ...CompareByDate(SortPayroll.inprogress),
            ...CompareByDate(SortPayroll.submitted),
            ...CompareByDate(SortPayroll.approved),
        ]
    }
}
function CompareByDate(array){

    return array.sort(function(a,b){
        return new Date(convertDateYYYYMMDD(b.datedue)) - new Date(convertDateYYYYMMDD(a.datedue));
    });
}

const SelectName = styled.div`
    display: block;
    cursor: pointer;
    svg{
     margin-left: 5px;
     position: relative;
     top: -1px;
     transform: ${props => props.show ? 'rotate(0deg)' : ' rotate(180deg)'};
    }
`;

const SelectOptions = styled.div`
    position: absolute;
    width: 129px;
    padding: 5px;
    margin-top: 13px;
    border: 1px solid ${props => props.theme.data.light.colors.Carbon};
    background: ${props => props.theme.data.light.colors.White};
    z-index: 2;
`;

const SelectOption = styled.span`
    ${props => props.theme.data.light.typography['body-primary-l']};
    cursor: pointer;
    display: block;
    text-transform: none;
    padding: 10px 0 10px 14px;
    line-height: 14px;
    background: ${props => props.active ? props.theme.data.light.colors.DirtySnow : 'transparent'};
`;

const SelectClear = styled.div`
    position: fixed;
    z-index: 1;
    background: transparent;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
`;

export default SelectStatus