import { createContext, useReducer } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

const StateContext = createContext({});
const DispatchContext = createContext({});

function reducer(state, action) {
  switch (action.type) {
    case 'all':
      return { ...state,
        crewList: action.payload.crew,
        payRollList: action.payload.payroll,
        singleMonthPayRoll: action.payload.singleMonth
      };
    case 'crewList':
      return { ...state, crewList: action.payload };
    case 'payRollList':
      return { ...state, payRollList: action.payload };
    case 'singleMonthPayRoll':
      return {...state,singleMonthPayRoll: action.payload};
    case 'payrollHistory':
      return { ...state, payrollHistory: action.payload };
    case 'language':
      return { ...state, language: action.payload };
    case 'total':
      return { ...state, total: action.payload };
    case 'currency':
      return { ...state, currency: action.payload };
    case 'submit':
      return { ...state, submit: action.payload };
    case 'approve':
      return { ...state, approve: action.payload };
    case 'process':
      return { ...state, process: action.payload };
    case 'reject':
      return { ...state, reject: action.payload };
    case 'unSavedChanges':
      return { ...state, unSavedChanges: action.payload };
    case 'unSavedChangesExpenses':
      return { ...state, unSavedChangesExpenses: action.payload };
    case 'loader':
      return { ...state, loader: action.payload };
    default:
      throw new Error();
  }
}

const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    crewList: null,
    payRollList: null,
    singleMonthPayRoll: null,
    language: null,
    submit: false,
    loader: false,
    reject: false,
    approve: false,
    process: false,
    payrollHistory: null,
    total: true,
    unSavedChanges: false,
    unSavedChangesExpenses: false,
    currency: getSymbolFromCurrency('EUR'),
  });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export { StateContext };
export { DispatchContext };
export { DataProvider };
