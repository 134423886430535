import axios from "axios";

export const getPayrollHistory = async (payrollid, username) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/get-payroll-history`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollid: payrollid,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    console.log("GET PAYROLL HISTORY");
    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getExchangeRate = async (
  date,
  currency,
  referencecurrency,
  username
) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/get-exchange-rate`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        date: date,
        currency: currency,
        referencecurrency: referencecurrency,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    console.log("GET EXCENGES RATE");
    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const processPayroll = async (payrollID, comment, username) => {
  console.log("processPayroll");

  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/process-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollID: payrollID,
        comment: comment,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const rejectPayroll = async (payrollID, comment, username) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/reject-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollID: payrollID,
        comment: comment,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const approvePayroll = async (payrollID, comment, username) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/approve-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollID: payrollID,
        comment: comment,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const submitPayroll = async (payrollID, comment, username) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/submit-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollID: payrollID,
        comment: comment,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteExpensesFile = async (file, username) => {
  try {
    console.log(file);
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/delete-expenses-file`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        file: file,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const downloadFile = async (filename) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/downloadFile`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: "blob",
      withCredentials: true,
      data: {
        filename: filename,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveExpenses = async (expenses, username) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/save-expenses`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        expenses: expenses,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCrewList = async function (yachtid, username) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/crew-list`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        yachtid: yachtid,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getNewPayRollId = async function (yachtid, username) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/create-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        yachtid: yachtid,
        username: username,
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPayRollList = async function (yachtid, username) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/payroll-list`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        yachtid: yachtid,
        username: username,
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;
    console.log("the data");
    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSingleMonthPayRoll = async function (monthPayrollID, username) {
  console.log("GET SINGLE MONTH");
  console.log(monthPayrollID);
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/list-payroll-items`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
      data: {
        payrollID: monthPayrollID,
        username: username,
      },
    }).catch((err) => err);
    let { data } = res;

    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const savePayroll = async function (Payroll, username) {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/save-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        payroll: Payroll,
        username: username,
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deletePayrollById = async function (PayrollID, username) {
  console.log("Delete Payroll");
  console.log(PayrollID);
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/delete-payroll`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        payrollID: PayrollID,
        username: username,
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSingleExpenses = async function (singlePayrollID, username) {
  console.log("GET SINGLE EXPENSES ITEMS");
  console.log(singlePayrollID);
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/list-expenses-items`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        payrollID: singlePayrollID,
        username: username,
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUser = async () => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/users/authenticateUser`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
      withCredentials: true,
    }).catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadFile = async (bodyFormData, setUploading, setShow) => {
  try {
    const res = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/api/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: bodyFormData,
      withCredentials: true,
      onUploadProgress: (data) => {
        console.log(Math.round((100 * data.loaded) / data.total));
        setUploading(Math.round((100 * data.loaded) / data.total));
      },
    })
      .finally(() => setShow(false))
      .catch((err) => err);
    let { data } = res;
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
