import styled,{css} from 'styled-components';

export const HistoryModal = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10;
`;

export const HistoryModalBG = styled.div`
    position: absolute;
    background: #090E36;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
`;

export const HistoryModalInner = styled.div`
    width: 688px;
    margin: 0 auto;
    display: block;
    background: ${props => props.theme.data.light.colors.Snow};
    z-index: 20;
    position: relative;
    margin-top: 42px;
    overflow: auto;
    padding: 36px 30px 52px 29px;
    max-height: calc(100vh - 80px);
`;

export const HistoryModalTop = styled.div`
   ${props => props.theme.data.light.typography['header-sm']};
   color: ${props => props.theme.data.light.colors.NightSky};
   text-align: left;
   margin-bottom: 63px;
`
export const HistoryModalClose = styled.div`
    position: absolute;
    right: 23px;
    top: 23px;
    cursor: pointer;
`

export const HistorySection = styled.div`

`

export const HistorySectionRow = styled.div`
  display: flex;
  flex:1;
  > div:nth-child(1){
     min-width: 125px;
     text-align: left;
  }
  > div:nth-child(2){
     padding: 0 32px 0 24px;
     position: relative;
  }
  > div:nth-child(2):after{
     content: "";
     position: absolute;
     width: 1px;
     height: calc(100%);
     background: ${props => props.theme.data.light.colors.DirtySnow}; 
     left: calc(50% - 4px);
     top: 0;
  }
`
export const HistorySectionDivAvatar = styled.div`
   position: relative;
   z-index: 10;
`

export const HistorySectionComment = styled.div`
    flex: 1;
    order: 2;
`

export const HistorySectionAvatar = styled.div`

`

export const HistorySectionStatus = styled.div`
 
`
export const HistorySectionStatusIn = styled.div`
   ${props => props.theme.data.light.typography['body-secondary-ss']}
   text-transform: uppercase;
   text-align: left;
   padding-top: 4px;
    ${props =>
        props.orange &&
        css`
          color: ${props => props.theme.data.light.colors.Orange};
        `};
    ${props =>
        !props.orange &&
        css`
         color: ${props => props.theme.data.light.colors.Spurle};
        `};
`

export const HistorySectionColumn = styled.div`
  
`
export const HistorySectionTime = styled.div`
    ${props => props.theme.data.light.typography['body-secondary-r']}
    color: ${props => props.theme.data.light.colors.NightSky};
`

export const CommentSection = styled.div`
    ${props => props.theme.data.light.typography['body-secondary-l']}
    background: ${props => props.theme.data.light.colors.White};
    color: ${props => props.theme.data.light.colors.NightSky};
    padding: 27px 30px 28px 30px;
    text-align: left;
    box-shadow: 0px 1px 2px rgba(9, 14, 54, 0.18);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 48px;
`

export const HistorySectionDiv = styled.div`
    text-align: left;
`

export const HistorySectionDivName = styled.div`
  ${props => props.theme.data.light.typography['body-primary-r']}
  color: ${props => props.theme.data.light.colors.NightSky};
`

export const HistorySectionDivRank = styled.div`
    ${props => props.theme.data.light.typography['body-primary-l']}
   color: ${props => props.theme.data.light.colors.NightSky};
   position: relative;
   top: -4px;
`
